import PropTypes from 'prop-types';
// @mui
import { Avatar, Stack, Grid, Divider } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// lodash
import { isNumber } from 'lodash';
// date-fns
import { parseISO } from 'date-fns';
// components
import { DataCardSection, DataCardRow } from '../../../components/DataCardSection';
// utils
import { getNameInitials } from '../../../utils/modelLabels';

// -------------------------------------------------------------------------

const EmployeeData = ({ data }) => {
  const { t } = useTranslation();

  // General Data
  const { photoUrl, firstName, lastName, nickname, phoneNumber, secondPhoneNumber } = data || {};

  // Personal Data
  const { dateOfBirth, nationality, placeOfBirth, address, idCardNumber, bloodType } = data || {};

  // Family Data
  const { maritalStatus, numberOfChildren, numberOfDependents } = data || {};

  if (!data) return null;

  return (
    <>
      <DataCardSection>
        <Grid container spacing={3} alignItems={'center'} justifyContent={'center'}>
          <Grid item style={{ flex: '0 1 auto' }}>
            <Avatar src={photoUrl} sx={{ width: { xs: 150, sm: 100 }, height: { xs: 150, sm: 100 } }}>
              {getNameInitials({ firstName, lastName })}
            </Avatar>
          </Grid>
          <Grid item style={{ flex: '1 1 auto' }}>
            <Stack spacing={1} divider={<Divider />}>
              <DataCardRow title={t('qrCodeInfoPage.employee.fullName.title')} text={`${firstName} ${lastName}`} />
              {Boolean(nickname) && <DataCardRow title={t('qrCodeInfoPage.employee.nickname.title')} text={nickname} />}
            </Stack>
          </Grid>
        </Grid>
      </DataCardSection>

      <DataCardSection title={t('qrCodeInfoPage.employee.sectionTitle.personalData')}>
        {phoneNumber && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.phoneNumber.title', { value: 1 })}
            text={phoneNumber}
            titleIconProps={{ icon: 'ion:call' }}
          />
        )}
        {secondPhoneNumber && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.phoneNumber.title', { value: 2 })}
            text={phoneNumber}
            titleIconProps={{ icon: 'ion:call-outline' }}
          />
        )}
        {dateOfBirth && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.dateOfBirth.title')}
            text={t('date.short', { date: parseISO(dateOfBirth) })}
            titleIconProps={{ icon: 'mdi:cake-variant-outline' }}
          />
        )}
        {nationality && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.nationality.title')}
            text={nationality}
            titleIconProps={{ icon: 'ion:earth-outline' }}
          />
        )}
        {placeOfBirth && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.placeOfBirth.title')}
            text={placeOfBirth}
            titleIconProps={{ icon: 'mdi:location-city' }}
          />
        )}
        {address && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.address.title')}
            text={address}
            titleIconProps={{ icon: 'ion:location-outline' }}
          />
        )}
        {idCardNumber && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.idCardNumber.title')}
            text={`${idCardNumber}`}
            titleIconProps={{ icon: 'mdi:card-account-details' }}
          />
        )}
        {bloodType && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.bloodType.title')}
            text={bloodType}
            titleIconProps={{ icon: 'mdi:blood-bag' }}
          />
        )}
      </DataCardSection>

      <DataCardSection title={t('qrCodeInfoPage.employee.sectionTitle.family')}>
        {maritalStatus && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.maritalStatus.title')}
            text={t(`maritalStatus.${maritalStatus}`)}
            titleIconProps={{ icon: 'mdi:ring' }}
          />
        )}
        {isNumber(numberOfChildren) && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.numberOfChildren.title')}
            text={`${numberOfChildren}`}
            titleIconProps={{ icon: 'mdi:human-male-child' }}
          />
        )}
        {isNumber(numberOfDependents) && (
          <DataCardRow
            title={t('qrCodeInfoPage.employee.numberOfDependents.title')}
            text={`${numberOfDependents}`}
            titleIconProps={{ icon: 'mdi:human-male-female-child' }}
          />
        )}
      </DataCardSection>
    </>
  );
};

EmployeeData.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EmployeeData;
