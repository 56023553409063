import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetAuthenticationActivity,
  GetFactoryOptions,
  UpdateAuthenticationActivity,
  UpdateAuthenticationActivityStatus,
} from './thunks';
// reducers
import {
  GetAuthenticationActivityReducer,
  UpdateAuthenticationActivityReducer,
  UpdateAuthenticationActivityStatusReducer,
  ClearSelectedAuthenticationActivityReducer,
  GetFactoryOptionsReducer,
  ResetAuthenticationActivityStateReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'authenticationActivity',
  initialState,
  reducers: {
    ClearSelectedAuthenticationActivity: ClearSelectedAuthenticationActivityReducer,
    ResetAuthenticationActivityState: ResetAuthenticationActivityStateReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetAuthenticationActivity, GetAuthenticationActivityReducer);
    addExtraReducer(builder, GetFactoryOptions, GetFactoryOptionsReducer);
    addExtraReducer(builder, UpdateAuthenticationActivity, UpdateAuthenticationActivityReducer);
    addExtraReducer(builder, UpdateAuthenticationActivityStatus, UpdateAuthenticationActivityStatusReducer);
  },
});

export default slice.reducer;
