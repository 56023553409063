import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// api
import employeesApi from '../../api/employeesApi';
import factoryOptionsApi from '../../api/factoryOptionsApi';
// utils
import { download } from '../../utils/files';
// constants
import { FactoryOptionNameEnum, LoadingStatusEnum } from '../../constants/enums';

// ----------------------------------------------------------------------------

const REDUCER_NAME = 'employees';

// THUNKS
export const CreateEmployee = createAsyncThunk(`${REDUCER_NAME}/createEmployee`, async (data, thunkAPI) => {
  const res = await employeesApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetEmployees = createAsyncThunk(`${REDUCER_NAME}/getEmployees`, async (__, thunkAPI) => {
  const res = await employeesApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateEmployee = createAsyncThunk(`${REDUCER_NAME}/updateEmployee`, async (data, thunkAPI) => {
  const res = await employeesApi.update(data);

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const GetEmployeePdf = createAsyncThunk(`${REDUCER_NAME}/getEmployeePdf`, async (id, thunkAPI) => {
  const res = await employeesApi.getPdf(id);

  if (res.status === 200) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const fileName = res.headers['x-suggested-filename'];
    download(url, fileName);

    // return data;
  }

  return thunkAPI.rejectWithValue();
});

export const GetEmployeeTypes = createAsyncThunk(`${REDUCER_NAME}/getEmployeeTypes`, async (__, thunkAPI) => {
  const res = await factoryOptionsApi.getByName(FactoryOptionNameEnum.EmployeeTypes);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const CreateEmployeeType = createAsyncThunk(`${REDUCER_NAME}/createEmployeeType`, async (value, thunkAPI) => {
  const res = await factoryOptionsApi.create({ optionName: FactoryOptionNameEnum.EmployeeTypes, value });

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const ChangeEmployeeInactiveStatus = createAsyncThunk(
  `${REDUCER_NAME}/ChangeEmployeeInactiveStatus`,
  async ({ id, inactive }, thunkAPI) => {
    const res = await employeesApi.changeInactiveStatus(id, inactive);

    if (res.status === 200) {
      return res.data;
    }

    return thunkAPI.rejectWithValue();
  }
);

// state
const initialState = {
  list: [],
  questionsForNewEmployee: {
    list: [],
    loading: 'idle',
  },
  employeeTypes: {
    list: [],
    loading: 'idle',
  },
  loading: 'idle',
};

// slice
export const employeesSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    ResetEmployeesState: () => initialState,
  },
  extraReducers: (builder) => {
    // ------------------GetEmployees-------------------------
    builder.addCase(GetEmployees.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetEmployees.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      questionsForNewEmployee: {
        list: action.payload.data?.factoryOptions?.questionsForNewEmployee || [],
        loading: 'succeeded',
      },
      loading: 'succeeded',
    }));

    builder.addCase(GetEmployees.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
    // ------------------UpdateEmployee-------------------------
    // builder.addCase(UpdateEmployee.pending, (state) => ({
    //   ...state,
    //   loading: 'pending',
    // }));

    builder.addCase(UpdateEmployee.fulfilled, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        list: state.list.map((employee) => {
          if (employee._id === data._id) return data;

          return employee;
        }),
        loading: 'succeeded',
      };
    });

    builder.addCase(UpdateEmployee.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
    // ------------------CreateEmployee-------------------------
    // builder.addCase(CreateEmployee.pending, (state) => ({
    //   ...state,
    //   loading: 'pending',
    // }));

    builder.addCase(CreateEmployee.fulfilled, (state, action) => {
      const { employee: newEmployee, questionsForNewEmployee } = action.payload.data;

      return {
        ...state,
        list: [...state.list, newEmployee],
        questionsForNewEmployee: {
          list: questionsForNewEmployee,
          loading: 'succeeded',
        },
        loading: 'succeeded',
      };
    });
    builder.addCase(CreateEmployee.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
    // ------------------GetEmployeeTypes-------------------------
    builder.addCase(GetEmployeeTypes.pending, (state) => ({
      ...state,
      employeeTypes: {
        ...state.options,
        loading: 'pending',
      },
    }));

    builder.addCase(GetEmployeeTypes.fulfilled, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        employeeTypes: {
          list: data,
          loading: 'succeeded',
        },
      };
    });
    builder.addCase(GetEmployeeTypes.rejected, (state) => ({
      ...state,
      employeeTypes: {
        ...state.options,
        loading: 'failed',
      },
    }));
    // ------------------CreateEmployeeType-------------------------
    // builder.addCase(CreateEmployeeType.pending, (state) => ({
    //   ...state,
    //   employeeTypes: {
    //     ...state.employeeTypes,
    //     loading: 'pending',
    //   },
    // }));

    builder.addCase(CreateEmployeeType.fulfilled, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        employeeTypes: {
          list: [...state.employeeTypes.list, data],
          loading: 'succeeded',
        },
      };
    });
    builder.addCase(CreateEmployeeType.rejected, (state) => ({
      ...state,
      employeeTypes: {
        ...state.employeeTypes,
        loading: 'failed',
      },
    }));

    // ------------------ChangeEmployeeInactiveStatus------------------
    builder.addCase(ChangeEmployeeInactiveStatus.pending, (state) => ({
      ...state,
      loading: LoadingStatusEnum.Pending,
    }));
    builder.addCase(ChangeEmployeeInactiveStatus.fulfilled, (state, action) => {
      const updatedList = state.list.map((employee) => {
        if (employee._id === action.payload.data._id) return action.payload.data;
        return employee;
      });
      return {
        ...state,
        list: updatedList,
        loading: LoadingStatusEnum.Succeeded,
      };
    });
    builder.addCase(ChangeEmployeeInactiveStatus.rejected, (state) => ({
      ...state,
      loading: LoadingStatusEnum.Failed,
    }));
  },
});

// Export Actions
// export const { } = userSlice.actions;

// Export Selectors
export const selectEmployeesList = (state) => state.employees.list;
export const selectEmployeesLoading = (state) => state.employees.loading;

export const selectEmployeeTypes = (state) => state.employees.employeeTypes;
export const selectQuestionsForNewEmployee = (state) => state.employees.questionsForNewEmployee;

export const { ResetEmployeesState } = employeesSlice.actions;

export default employeesSlice.reducer;
