import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// mui
import { Card, CardHeader, Box, CircularProgress } from '@mui/material';
// chart
import ReactApexChart from 'react-apexcharts';
// day-js
import dayjs from 'dayjs';
// i18n
import { useTranslation } from 'react-i18next';
// lodash
import _ from 'lodash';
// react-redux
import { useDispatch, useSelector } from 'react-redux';
// redux
import { GetTobaccos } from '../../../../redux/reducers/tobaccos/thunks';
import selectors from '../../../../redux/reducers/tobaccos/selectors';
// axios
import { axiosApiInstance } from '../../../../api/axios';
// components
import { BaseOptionChart } from '../../../../components/chart';
import TobaccoClassificationChartFilters from './TobaccoClassificationChartFilters';
// utils
import { getTobaccoLabel } from '../../../../utils/modelLabels';
import ToastEmitter from '../../../../components/toaster/ToastEmmited';

// -----------------------------------------------------------------

const TobaccoClassificationChart = ({ title, subheader }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // redux state
  const tobaccos = useSelector(selectors.list);
  const tobaccosIsLoading = useSelector(selectors.isLoading);

  // chart data
  const baseOptions = BaseOptionChart();

  const [list, setList] = useState([]);

  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  const onGetTobaccoTransactionsClassification = async (params) => {
    try {
      if (params.tobacco) {
        const response = await axiosApiInstance.get(`tobaccoTransactions/classifications`, {
          params: {
            ...params,
            startDate: dayjs(params.startDate).format('YYYY-MM-DD'),
            endDate: dayjs(params.endDate).format('YYYY-MM-DD'),
          },
        });

        const list = response.data.data;

        setList(list);
      }
    } catch (error) {
      ToastEmitter.notify(`Error fetching tobacco costPerUnit data: ${error}`, 'error');
    }
  };

  const onGetTobaccos = () => {
    dispatch(GetTobaccos({ isShowInactive: false }));
  };

  useEffect(() => {
    onGetTobaccos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // const allDates = _.chain(list)
    //   .map((classificationInput) => new Date(classificationInput.createdAt))
    //   .sort()
    //   .value();

    const groupedData = list.reduce((acc, classificationInput) => {
      classificationInput.classifieds.forEach((classified) => {
        const tobaccoId = classified.tobacco._id;

        if (!acc[tobaccoId]) {
          acc[tobaccoId] = {
            name: getTobaccoLabel(classified.tobacco),
            data: [],
          };
        }

        acc[tobaccoId].data.push({
          x: new Date(classificationInput.createdAt),
          y: (classified.percentage || 0).toFixed(2),
        });
      });

      return acc;
    }, {});

    const series = Object.values(groupedData);

    // Chart configuration
    const chartOptions = {
      // plotOptions: { bar: { columnWidth: '16%' } },
      series,
      xaxis: {
        // categories:
        //   series.length > 0 ? allDates.map((date) => t('date.shortWithTime', { date: new Date(date) })) : [''],
        type: 'datetime',
        labels: {
          formatter: (date) => {
            if (date && series.length > 0) return t('date.shortWithTime', { date: new Date(date) });

            return '';
          },
        },
      },
      yaxis: {
        title: {
          text: 'Percent, %',
        },
      },
      title: {
        text: 'Classification Tobaccos',
        align: 'left',
      },
      // legend: {
      //   show: false,
      // },
    };

    setChartData({
      series,
      options: _.merge(baseOptions, chartOptions),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, list]);

  return (
    <Card>
      <CardHeader title={title} subheader={subheader} />

      {tobaccosIsLoading ? (
        <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Box sx={{ p: 3, pb: 1 }}>
          <Box pb={1}>
            <TobaccoClassificationChartFilters tobaccos={tobaccos} onSubmit={onGetTobaccoTransactionsClassification} />
          </Box>
          <ReactApexChart type="line" series={chartData.series} options={chartData.options} height={500} />
        </Box>
      )}
    </Card>
  );
};

TobaccoClassificationChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default TobaccoClassificationChart;
