import PropTypes from 'prop-types';
// @mui
import { Box, Stack, MenuItem, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// i18n
import { useTranslation } from 'react-i18next';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import { FormProvider, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
// services
import { UserRoleEnum } from '../../../../../services/access';
// defaultValues
import { getDefaultValues } from './defaultValues';
// schema
import schema from './yupSchema';

//-------------------------------------------------------------------------------------------------

const EditUserForm = ({ data, id, onUpdate, sx }) => {
  const { t } = useTranslation();

  const defaultValues = data ? getDefaultValues(data) : {};

  const methods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    onUpdate({
      data,
      id,
    });
  };

  return (
    <Box sx={sx}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="fullName" label={t('usersPage.field.fullName')} required autoFocus />
            <RHFTextField name="email" label={t('usersPage.field.email')} required />
          </Stack>

          <RHFSelect name={'role'} label={t('usersPage.field.role')} required>
            {Object.values(UserRoleEnum).map((role) => {
              if (
                ![UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.BrandOwner].includes(role) &&
                data?.role !== role
              ) {
                return null;
              }

              return (
                <MenuItem key={role} value={role}>
                  {t(`roles.${role}`)}
                </MenuItem>
              );
            })}
          </RHFSelect>

          <Divider sx={{ my: 2 }} />
          <Stack alignItems={'flex-end'} direction={'column'}>
            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              {t('usersPage.button.edit')}
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Box>
  );
};

EditUserForm.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  id: PropTypes.string,
  sx: PropTypes.object,
};

export default EditUserForm;
