import { useEffect } from 'react';
// @mui
import { Stack, Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// redux
import { useDispatch, useSelector } from 'react-redux';
import {
  GetNotifications,
  MarkAllNotificationsAsRead,
  MarkNotificationAsRead,
  MarkNotificationAsUnRead,
} from '../redux/reducers/userNotifications/thunks';
import selectors from '../redux/reducers/userNotifications/selectors';
// components
import Page from '../components/Page';
import UserNotificationsTable from '../sections/@dashboard/userNotifications/UserNotificationsTable';
// hooks
import { useSocket } from '../providers/SocketProvider';

// ----------------------------------------------------------------------

export default function UserNotifications() {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { socket } = useSocket();

  const list = useSelector(selectors.list);
  const loading = useSelector(selectors.loading);

  const onGet = () => {
    dispatch(GetNotifications());
  };

  const onMarkAllAsRead = () => {
    dispatch(MarkAllNotificationsAsRead());
  };

  const onMarkAsRead = (id) => {
    dispatch(MarkNotificationAsRead(id));
  };

  const onMarkAsUnRead = (id) => {
    dispatch(MarkNotificationAsUnRead(id));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onGet, []);

  useEffect(() => {
    socket?.on('sendNotification', onGet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <Page title={t('pages.notifications')}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          {t('pages.notifications')}
        </Typography>
      </Stack>

      <UserNotificationsTable
        list={list}
        loading={loading}
        onMarkAsRead={onMarkAsRead}
        onMarkAsUnRead={onMarkAsUnRead}
        onMarkAllAsRead={onMarkAllAsRead}
      />
    </Page>
  );
}
