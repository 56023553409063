import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetNotifications,
  GetLastNotifications,
  GetNotificationsTotalUnread,
  MarkNotificationAsRead,
  MarkNotificationAsUnRead,
  MarkAllNotificationsAsRead,
} from './thunks';
// reducers
import {
  GetNotificationsReducer,
  GetLastNotificationsReducer,
  GetNotificationsTotalUnreadReducer,
  MarkNotificationAsReadReducer,
  MarkNotificationAsUnreadReducer,
  MarkAllNotificationsAsReadReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'userNotifications',
  initialState,
  extraReducers: (builder) => {
    addExtraReducer(builder, GetNotifications, GetNotificationsReducer);
    addExtraReducer(builder, GetLastNotifications, GetLastNotificationsReducer);
    addExtraReducer(builder, GetNotificationsTotalUnread, GetNotificationsTotalUnreadReducer);
    addExtraReducer(builder, MarkNotificationAsRead, MarkNotificationAsReadReducer);
    addExtraReducer(builder, MarkNotificationAsUnRead, MarkNotificationAsUnreadReducer);
    addExtraReducer(builder, MarkAllNotificationsAsRead, MarkAllNotificationsAsReadReducer);
  },
});

export default slice.reducer;
