import PropTypes from 'prop-types';
// mui
import { Accordion, AccordionDetails, AccordionSummary, Card, CardHeader, Typography, Box, Stack } from '@mui/material';
// date-fns
import { parseISO } from 'date-fns';
// lodash
import { isArray, isNumber, some } from 'lodash';
// i18n
import { useTranslation } from 'react-i18next';
// components
import Iconify from '../../components/Iconify';
// utils
import { getCigarLabel } from '../../utils/modelLabels';

// ----------------------------------------------------------------------------------------

const CigarInfo = ({ cigar, sx }) => {
  const { t } = useTranslation();

  // General Data
  const { blendName, inventedAt, publicName, description } = cigar;

  // tier
  const tier = cigar.tier?.value;

  // Shape
  const { shape } = cigar;
  const { length, ringGauge, name: shapeName } = shape || {};

  // Leaves
  const { tobaccoLeaves } = cigar;
  const { filler, wrapper, binder } = tobaccoLeaves || {};

  return (
    <Card sx={sx}>
      <Accordion>
        <AccordionSummary
          expandIcon={<Iconify width={20} height={20} icon="eva:arrow-ios-downward-fill" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <CardHeader
            sx={{ pt: 0 }}
            title={getCigarLabel({ shape, blendName })}
            subheader={publicName && `Public Name - ${publicName}`}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1} sx={{ px: 3 }}>
            {inventedAt && (
              <Box>
                <Typography color="primary.main">Invented At</Typography>
                <Typography color="textSecondary" variant="body2">
                  {t('date.short', { date: parseISO(inventedAt) })}
                </Typography>
              </Box>
            )}
            {description && (
              <Box>
                <Typography color="primary.main">Description</Typography>
                <Typography color="textSecondary" variant="body2">
                  {description}
                </Typography>
              </Box>
            )}
            {tier && (
              <Box>
                <Typography color="primary.main">Tier</Typography>
                <Typography color="textSecondary" variant="body2">
                  {tier}
                </Typography>
              </Box>
            )}
            <Box>
              <Typography color="primary.main">Shape</Typography>
              {shapeName?.value && (
                <Typography color="textSecondary" variant="body2">
                  Name - <b>{shapeName.value}</b>
                  <br />
                </Typography>
              )}
              {isNumber(length) && isNumber(ringGauge) && (
                <Typography color="textSecondary" variant="body2">
                  Length - <b>{length} in</b>, Ring Gauge - <b>{ringGauge} in</b>
                </Typography>
              )}
            </Box>
            {some(tobaccoLeaves, (value) => isArray(value) && value?.length > 0) && (
              <Box>
                <Typography color="primary.main">Tobacco Leaves</Typography>
                <Typography color="textSecondary" variant="body2">
                  {filler.length > 0 && (
                    <>
                      Filler - <b>{filler.join(', ')}</b>
                      <br />
                    </>
                  )}
                  {wrapper.length > 0 && (
                    <>
                      Wrapper - <b>{wrapper.join(', ')}</b>
                      <br />
                    </>
                  )}
                  {binder.length > 0 && (
                    <>
                      Binder - <b>{binder.join(', ')}</b>
                      <br />
                    </>
                  )}
                </Typography>
              </Box>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

CigarInfo.propTypes = {
  cigar: PropTypes.object,
  sx: PropTypes.object,
};

export default CigarInfo;
