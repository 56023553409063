import initialState from './initialState';
import { LoadingStatusEnum } from '../../../constants/enums';

export const GetConveyorTemplatesReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: data || [],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetConveyorTemplateByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const GetConveyorStepTemplateByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedStepTemplate: {
      ...state.selectedStepTemplate,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedStepTemplate: {
        data,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedStepTemplate: {
      ...state.selectedStepTemplate,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const UpdateConveyorTemplateReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const ClearSelectedConveyorTemplateReducer = (state) => ({
  ...state,
  selectedItem: {
    data: null,
    loading: 'idle',
  },
});

export const ClearSelectedConveyorStepTemplateReducer = (state) => ({
  ...state,
  selectedStepTemplate: {
    data: null,
    loading: 'idle',
  },
});

export const ResetConveyorTemplatesReducer = () => initialState;

export const UpdateConveyorStepTemplateReducer = {
  pending: (state) => ({
    ...state,
    selectedStepTemplate: {
      ...state.selectedStepTemplate,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedStepTemplate: {
        data,
        loading: 'succeeded',
      },
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedStepTemplate: {
      ...state.selectedStepTemplate,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const ChangeConveyorStepTemplateInactiveStatusReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => {
    const { list } = state;
    const conveyorSteps = action.payload.data;
    const updatedList = list.map((item) => {
      if (item._id === conveyorSteps._id) return conveyorSteps;
      return item;
    });
    return {
      ...state,
      list: updatedList,
      loading: LoadingStatusEnum.Succeeded,
    };
  },
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};
