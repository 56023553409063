import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Box, CircularProgress, Container, Typography } from '@mui/material';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetQRByQrId, selectQrCodeData, selectQrCodeLoading } from '../redux/reducers/QrCodesReducer';
// components
import Page from '../components/Page';
// sections
import CigarUnitQRCode from '../sections/qrCode/CigarUnitQRCode';
import EmployeeQRCode from '../sections/qrCode/EmployeeQRCode';
import EmptyQRCode from '../sections/qrCode/EmptyQRCode';
import CigarQrCode from '../sections/qrCode/CigarQrCode';
import TobaccoContainerQRCode from '../sections/qrCode/TobaccoContainerQRCode';

// enums
import { QRTypeEnum } from '../constants/qrTypesEnum';

// ----------------------------------------------------------------------

const UnitByQR = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const loading = useSelector(selectQrCodeLoading);
  const qrCode = useSelector(selectQrCodeData);
  useEffect(() => {
    dispatch(GetQRByQrId({ factory: params.factory, qrId: params.qrId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.qrId]);

  if (loading === 'pending') {
    return (
      <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress disableShrink />
      </Box>
    );
  }

  switch (qrCode.type) {
    case QRTypeEnum.Empty:
      return <EmptyQRCode qrCode={qrCode} />;
    case QRTypeEnum.Employee:
      return <EmployeeQRCode qrCode={qrCode} />;
    case QRTypeEnum.Cigar:
      return <CigarQrCode qrCode={qrCode} />;
    case QRTypeEnum.CigarUnit:
      return <CigarUnitQRCode />;
    case QRTypeEnum.TobaccoContainer:
      return <TobaccoContainerQRCode qrCode={qrCode} />;
    default:
      // TODO: Fix Logo only layout and remove py={12}
      return (
        <Page py={12}>
          <Container maxWidth={'md'}>
            <Typography variant="h5" color="primary.main">
              {qrCode.type || 'Undefined'} QR Code component
            </Typography>
          </Container>
        </Page>
      );
  }
};

export default UnitByQR;
