import { useEffect, lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
//
import { Box, CircularProgress } from '@mui/material';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
// import Blog from './pages/Blog';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import AccessDenied from './pages/AccessDenied';
import PageInProgress from './pages/PageInProgress';

import UserNotifications from './pages/UserNotifications';
// import Factories from './pages/Factories';
import { AuthenticationActivity } from './pages/AuthenticationActivity';
import { UsersWithoutFactory } from './pages/UsersWithoutFactory';
// import Users from './pages/Users';
// import Cigars from './pages/Cigars';
// import CigarPrices from './pages/CigarPrices';
// import Tobaccos from './pages/Tobaccos/Tobaccos';
// import Rooms from './pages/Rooms';
// import Distributors from './pages/Distributors';
// import FactoryOptions from './pages/FactoryOptions';
// import Taxes from './pages/Taxes';
// import Employees from './pages/Employees';
// import EmployeeTeams from './pages/EmployeeTeams';
// import Attendances from './pages/Attendances';
// import Payrolls from './pages/payrolls';
// import PayrollsAdd from './pages/payrolls/add';
// import PayrollsEdit from './pages/payrolls/edit';
// import Expenses from './pages/Expenses';
// import Invoices from './pages/Invoices';
// import Suppliers from './pages/Suppliers';
// import CigarPerformance from './pages/CigarPerformance';
// import ConveyorCigarUnitProductions from './pages/ConveyorCigarUnitProductions';
// import EmployeeUnitProductions from './pages/EmployeeUnitProductions';
// import ConveyorStepRecords from './pages/ConveyorStepRecords';
// import ConveyorStepRecordsHistory from './pages/ConveyorStepRecordsHistory';
// import TobaccoInventory from './pages/TobaccoInventory';
// import { TobaccoMappingPage } from './pages/TobaccoMappingPage';
// import { WorkInProgressInventory } from './pages/WorkInProgressInventory';
// import WorkInProgressInventoryHistory from './pages/WorkInProgressInventoryHistory/WorkInProgressInventoryHistory';
// import TobaccoProduction from './pages/TobaccoProduction';
// import TobaccoTransactions from './pages/TobaccoTransactions';
// import FinishedGoodInventory from './pages/FinishedGoodInventory';
// import RawMaterialInventory from './pages/RawMaterialInventory/RawMaterialInventory';
// import PackingLists from './pages/PackingLists';
// import DepartmentOrder from './pages/DepartmentOrder';
// import WorkOrders from './pages/WorkOrders';
// import PurchaseOrder from './pages/PurchaseOrder';
// import TobaccoSales from './pages/TobaccoSales';
// import CycleCount from './pages/CycleCount';
// import ConveyorTemplates from './pages/ConveyorTemplates';
// import TobaccoClassificationSummary from './pages/TobaccoClassificationSummary';
// import FinishedGoodTemplates from './pages/FinishedGoodTemplates';

import UnitByQR from './pages/UnitByQR';
// import QrCodes from './pages/QrCodes';
// import CigarUnitsPeriodEndPage from './pages/CigarUnitsPeriodEnd';
// import CigarUnitsPage from './pages/CigarUnits';
// import CigarUnitProduction from './pages/CigarUnitProduction';
// import Register from './pages/Register';
// import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
// useAuth
import { useAuth } from './providers/AuthProvider';
// protected
import { ProtectedRoute } from './components/ProtectedRoute';
// import { BoxPressedProcess } from './pages/BoxPressedProcess';
// import { CigarUnitBoxProduction } from './pages/CigarUnitBoxProduction';

const Factories = lazy(() => import('./pages/Factories'));
const Users = lazy(() => import('./pages/Users'));
const Cigars = lazy(() => import('./pages/Cigars'));
const Tobaccos = lazy(() => import('./pages/Tobaccos/Tobaccos'));
const Rooms = lazy(() => import('./pages/Rooms'));
const Distributors = lazy(() => import('./pages/Distributors'));
const FactoryOptions = lazy(() => import('./pages/FactoryOptions'));
const Taxes = lazy(() => import('./pages/Taxes'));
const Employees = lazy(() => import('./pages/Employees'));
const EmployeeTeams = lazy(() => import('./pages/EmployeeTeams'));
const Attendances = lazy(() => import('./pages/Attendances'));
const CigarPerformance = lazy(() => import('./pages/CigarPerformance'));
const TobaccoInventory = lazy(() => import('./pages/TobaccoInventory'));
const ConveyorTemplates = lazy(() => import('./pages/ConveyorTemplates'));
const TobaccoClassificationSummary = lazy(() => import('./pages/TobaccoClassificationSummary'));
const CigarPrices = lazy(() => import('./pages/CigarPrices'));
const EmployeeUnitProductions = lazy(() => import('./pages/EmployeeUnitProductions'));
const ConveyorStepRecords = lazy(() => import('./pages/ConveyorStepRecords'));
const ConveyorStepRecordsHistory = lazy(() => import('./pages/ConveyorStepRecordsHistory'));
const ConveyorCigarUnitProductions = lazy(() => import('./pages/ConveyorCigarUnitProductions'));
const CigarUnitBoxProduction = lazy(() =>
  import('./pages/CigarUnitBoxProduction/CigarUnitBoxProduction').then((module) => ({
    default: module.CigarUnitBoxProduction,
  }))
);
const BoxPressedProcess = lazy(() => import('./pages/BoxPressedProcess/BoxPressedProcess'));
const WorkInProgressInventory = lazy(() => import('./pages/WorkInProgressInventory/WorkInProgressInventory'));
const WorkInProgressInventoryHistory = lazy(() =>
  import('./pages/WorkInProgressInventoryHistory/WorkInProgressInventoryHistory')
);
const TobaccoProduction = lazy(() => import('./pages/TobaccoProduction'));
const TobaccoHumidityRoomProductionPage = lazy(() => import('./pages/TobaccoHumidityRoomProductionPage'));
const TobaccoTransactions = lazy(() => import('./pages/TobaccoTransactions'));
const TobaccoMappingPage = lazy(() => import('./pages/TobaccoMappingPage/TobaccoMappingsPage'));
const FinishedGoodInventory = lazy(() => import('./pages/FinishedGoodInventory'));
const RawMaterialInventory = lazy(() => import('./pages/RawMaterialInventory/RawMaterialInventory'));
const PackingLists = lazy(() => import('./pages/PackingLists'));
const DepartmentOrder = lazy(() => import('./pages/DepartmentOrder'));
const WorkOrders = lazy(() => import('./pages/WorkOrders'));
const PurchaseOrder = lazy(() => import('./pages/PurchaseOrder'));
const Suppliers = lazy(() => import('./pages/Suppliers'));
const Payrolls = lazy(() => import('./pages/payrolls'));
const PayrollsAdd = lazy(() => import('./pages/payrolls/add'));
const PayrollsEdit = lazy(() => import('./pages/payrolls/edit'));
const Invoices = lazy(() => import('./pages/Invoices'));
const Expenses = lazy(() => import('./pages/Expenses'));
const QrCodes = lazy(() => import('./pages/QrCodes'));
const CigarUnitsPage = lazy(() => import('./pages/CigarUnits'));
const CigarUnitsPeriodEndPage = lazy(() => import('./pages/CigarUnitsPeriodEnd'));
const CigarUnitProduction = lazy(() => import('./pages/CigarUnitProduction'));
const TobaccoSales = lazy(() => import('./pages/TobaccoSales'));
const CycleCount = lazy(() => import('./pages/CycleCount'));
const FinishedGoodTemplates = lazy(() => import('./pages/FinishedGoodTemplates'));

// ----------------------------------------------------------------------

export default function RouterWrapper() {
  const { onGetMe, isAuthentificated, loading } = useAuth();

  useEffect(() => {
    onGetMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return <Router isAuthentificated={isAuthentificated} />;
}

function Router({ isAuthentificated }) {
  return useRoutes([
    {
      path: '/:factory/unit/:qrId',
      element: <UnitByQR />,
    },
    {
      path: '/dashboard',
      element: isAuthentificated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        {
          path: 'user-notifications',
          element: <UserNotifications />,
        },
        {
          path: 'factories',
          element: (
            <ProtectedRoute path={'factories'}>
              <Suspense fallback={<CircularProgress />}>
                <Factories />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'authentication-activity',
          element: (
            <ProtectedRoute path={'authenticationActivity'}>
              <AuthenticationActivity />
            </ProtectedRoute>
          ),
        },
        {
          path: 'users-without-factory',
          element: (
            <ProtectedRoute path={'usersWithoutFactory'}>
              <UsersWithoutFactory />
            </ProtectedRoute>
          ),
        },

        {
          path: 'users',
          element: (
            <ProtectedRoute path={'users'}>
              <Suspense fallback={<CircularProgress />}>
                <Users />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigars',
          element: (
            <ProtectedRoute path={'cigars'}>
              <Suspense fallback={<CircularProgress />}>
                <Cigars />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'conveyors',
          element: (
            <ProtectedRoute path={'conveyors'}>
              <Suspense fallback={<CircularProgress />}>
                <ConveyorTemplates />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-classification-summary',
          element: (
            <ProtectedRoute path={'tobaccoClassificationSummary'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoClassificationSummary />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-prices',
          element: (
            <ProtectedRoute path={'cigarPrices'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarPrices />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobaccos',
          element: (
            <ProtectedRoute path={'tobaccos'}>
              <Suspense fallback={<CircularProgress />}>
                <Tobaccos />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'rooms',
          element: (
            <ProtectedRoute path={'rooms'}>
              <Suspense fallback={<CircularProgress />}>
                <Rooms />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'distributors',
          element: (
            <ProtectedRoute path={'distributors'}>
              <Suspense fallback={<CircularProgress />}>
                <Distributors />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'factory-options',
          element: (
            <ProtectedRoute path={'factoryOptions'}>
              <Suspense fallback={<CircularProgress />}>
                <FactoryOptions />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'taxes',
          element: (
            <ProtectedRoute path={'taxes'}>
              <Suspense fallback={<CircularProgress />}>
                <Taxes />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'employees',
          element: (
            <ProtectedRoute path={'employees'}>
              <Suspense fallback={<CircularProgress />}>
                <Employees />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'employee-teams',
          element: (
            <ProtectedRoute path={'employeeTeams'}>
              <Suspense fallback={<CircularProgress />}>
                <EmployeeTeams />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'attendances',
          element: (
            <ProtectedRoute path={'attendances'}>
              <Suspense fallback={<CircularProgress />}>
                <Attendances />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-performance',
          element: (
            <ProtectedRoute path={'cigarPerformance'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarPerformance />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'employeeUnitProductions',
          element: (
            <ProtectedRoute path={'employeeUnitProductions'}>
              <Suspense fallback={<CircularProgress />}>
                <EmployeeUnitProductions />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'conveyor-step-records',
          element: (
            <ProtectedRoute path={'conveyorStepRecords'}>
              <Suspense fallback={<CircularProgress />}>
                <ConveyorStepRecords />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'conveyor-step-records-history',
          element: (
            <ProtectedRoute path={'conveyorStepRecordsHistory'}>
              <Suspense fallback={<CircularProgress />}>
                <ConveyorStepRecordsHistory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-inventory',
          element: (
            <ProtectedRoute path={'tobaccoInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoInventory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-production',
          element: (
            <ProtectedRoute path={'conveyorCigarUnitProductions'}>
              <Suspense fallback={<CircularProgress />}>
                <ConveyorCigarUnitProductions />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-unit-box-productions',
          element: (
            <ProtectedRoute path={'cigarUnitBoxProductions'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarUnitBoxProduction />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'box-pressed-process',
          element: (
            <ProtectedRoute path={'boxPressedProcess'}>
              <Suspense fallback={<CircularProgress />}>
                <BoxPressedProcess />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'workInProgressInventory',
          element: (
            <ProtectedRoute path={'workInProgressInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <WorkInProgressInventory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'work-in-progress-inventory-history',
          element: (
            <ProtectedRoute path={'workInProgressInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <WorkInProgressInventoryHistory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-production',
          element: (
            <ProtectedRoute path={'tobaccoProduction'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoProduction />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-humidity-room-production',
          element: (
            // <ProtectedRoute path={'tobaccoHumidityRoomProduction'}>
            <Suspense fallback={<CircularProgress />}>
              <TobaccoHumidityRoomProductionPage />
            </Suspense>
            // </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-transactions',
          element: (
            <ProtectedRoute path={'tobaccoTransactions'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoTransactions />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-mappings',
          element: (
            <ProtectedRoute path={'tobaccoMappings'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoMappingPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'finished-good-inventory',
          element: (
            <ProtectedRoute path={'finishedGoodInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <FinishedGoodInventory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'rawMaterialInventory',
          element: (
            <ProtectedRoute path={'rawMaterialInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <RawMaterialInventory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'packing-lists',
          element: (
            <ProtectedRoute path={'packingLists'}>
              <Suspense fallback={<CircularProgress />}>
                <PackingLists />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'department-order',
          element: (
            <ProtectedRoute path={'departmentOrder'}>
              <Suspense fallback={<CircularProgress />}>
                <DepartmentOrder />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'work-orders',
          element: (
            <ProtectedRoute path={'workOrders'}>
              <Suspense fallback={<CircularProgress />}>
                <WorkOrders />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'purchase-order',
          element: (
            <ProtectedRoute path={'purchaseOrder'}>
              <Suspense fallback={<CircularProgress />}>
                <PurchaseOrder />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'suppliers',
          element: (
            <ProtectedRoute path={'suppliers'}>
              <Suspense fallback={<CircularProgress />}>
                <Suppliers />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'payrolls',
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute path={'payrolls'}>
                  <Suspense fallback={<CircularProgress />}>
                    <Payrolls />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: 'add',
              element: (
                <ProtectedRoute path={'payrolls'}>
                  <Suspense fallback={<CircularProgress />}>
                    <PayrollsAdd />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: 'edit/:id',
              element: (
                <ProtectedRoute path={'payrolls'}>
                  <Suspense fallback={<CircularProgress />}>
                    <PayrollsEdit />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'invoices',
          element: (
            <ProtectedRoute path={'invoices'}>
              <Suspense fallback={<CircularProgress />}>
                <Invoices />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'expenses',
          element: (
            <ProtectedRoute path={'expenses'}>
              <Suspense fallback={<CircularProgress />}>
                <Expenses />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'qr-codes',
          element: (
            <ProtectedRoute path={'qrCodes'}>
              <Suspense fallback={<CircularProgress />}>
                <QrCodes />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-units',
          element: (
            <ProtectedRoute path={'cigarUnits'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarUnitsPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-units-period-end',
          element: (
            <ProtectedRoute path={'cigarUnitsPeriodEnd'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarUnitsPeriodEndPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-unit-productions',
          element: (
            <ProtectedRoute path={'cigarUnitProductions'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarUnitProduction />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-sales',
          element: (
            <ProtectedRoute path={'tobaccoSales'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoSales />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cycle-count',
          element: (
            <ProtectedRoute path={'cycleCount'}>
              <Suspense fallback={<CircularProgress />}>
                <CycleCount />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'finished-good-templates',
          element: (
            <ProtectedRoute path={'finishedGoodTemplates'}>
              <Suspense fallback={<CircularProgress />}>
                <FinishedGoodTemplates />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: 'login',
      element: isAuthentificated ? <Navigate to="/" /> : <Login />,
    },
    // {
    //   path: 'register',
    //   element: <Register />,
    // },
    {
      path: '/',
      element: isAuthentificated ? <LogoOnlyLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: 'in-progress', element: <PageInProgress /> },
        { path: 'access-denied', element: <AccessDenied /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
