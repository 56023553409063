import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetFinishedGoodTemplates,
  GetFinishedGoodTemplateById,
  UpdateFinishedGoodTemplate,
  CreateFactoryOption,
  GetFinishedGoodTemplatesFactoryOptions,
  GetFinishedGoodTemplatesProductBrands,
  CreateProductBrand,
} from './thunks';
// reducers
import {
  ClearSelectedFinishedGoodTemplateReducer,
  GetFinishedGoodTemplateByIdReducer,
  GetFinishedGoodTemplatesReducer,
  UpdateFinishedGoodTemplateReducer,
  CreateFactoryOptionReducer,
  GetFinishedGoodTemplatesProductBrandsReducer,
  GetFactoryOptionsReducer,
  CreateProductBrandReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'finishedGoodTemplate',
  initialState,
  reducers: { ClearSelectedFinishedGoodTemplate: ClearSelectedFinishedGoodTemplateReducer },
  extraReducers: (builder) => {
    // GET
    addExtraReducer(builder, GetFinishedGoodTemplates, GetFinishedGoodTemplatesReducer);
    addExtraReducer(builder, GetFinishedGoodTemplateById, GetFinishedGoodTemplateByIdReducer);
    addExtraReducer(builder, GetFinishedGoodTemplatesFactoryOptions, GetFactoryOptionsReducer);
    addExtraReducer(builder, GetFinishedGoodTemplatesProductBrands, GetFinishedGoodTemplatesProductBrandsReducer);
    // UPDATE
    addExtraReducer(builder, UpdateFinishedGoodTemplate, UpdateFinishedGoodTemplateReducer);
    // CREATE
    addExtraReducer(builder, CreateFactoryOption, CreateFactoryOptionReducer);
    addExtraReducer(builder, CreateProductBrand, CreateProductBrandReducer);
  },
});

export default slice.reducer;
