import { createAsyncThunk } from '@reduxjs/toolkit';
// dayjs
import dayjs from 'dayjs';
// api
import cigarPerformanceApi from '../../../api/cigarPerformanceApi';
import cigarProductionsApi from '../../../api/cigarProductionsApi';
import payrollApi from '../../../api/payrollApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'cigarPerformance';

export const GetCigarPerformance = createAsyncThunk(`${REDUCER_NAME}/getCigarPerformance`, async (params, thunkAPI) => {
  const res = await cigarPerformanceApi.get({
    ...params,
    startDate: dayjs(params.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(params.endDate).format('YYYY-MM-DD'),
  });

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const CalculateCigarUnitsCost = createAsyncThunk(
  `${REDUCER_NAME}/calculateCigarUnitsCost`,
  async (params, thunkAPI) => {
    params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

    const res = await cigarProductionsApi.cigarUnitProductionsApi.calculateCigarUnitsCost(params);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetPayroll = createAsyncThunk(`${REDUCER_NAME}/getPayroll`, async (params, thunkAPI) => {
  const res = await payrollApi.getByWeek(params);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetPayrollWarnings = createAsyncThunk(`${REDUCER_NAME}/getPayrollWarnings`, async (params, thunkAPI) => {
  const res = await payrollApi.getWarnings(params);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
