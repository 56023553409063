// mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
// lodash
import { sortBy } from 'lodash';
// i18n
import { useTranslation } from 'react-i18next';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { selectAlertLoading, selectAlert, CloseAlert } from '../../redux/reducers/AlertReducer';
// components
import StyledModal from '../StyledModal';

// -----------------------------------------------------------------------------------------------

export function TableValidationAlert() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loading = useSelector(selectAlertLoading);
  const { code, tableValidationErrors } = useSelector(selectAlert);

  const handleClose = () => dispatch(CloseAlert());

  const isOpen = loading === 'succeeded' || loading === 'pending';

  if (!isOpen) return null;

  const sortedRows = sortBy(Object.keys(tableValidationErrors), (item) => {
    if (item === '_general') return 0;
    return Number(item);
  });

  return (
    <StyledModal maxWidth={'sm'} open={isOpen} handleClose={handleClose} title={t(code)}>
      <Box display={'flex'} flexDirection={'column'}>
        {loading === 'pending' ? (
          <CircularProgress />
        ) : loading === 'succeeded' ? (
          <Stack w={'100%'} spacing={1}>
            {sortedRows.map((rowIndex) =>
              rowIndex === '_general' ? (
                tableValidationErrors[rowIndex].map((message) => (
                  <Typography
                    textAlign={'center'}
                    key={message}
                    color={'text.primary'}
                    dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br />') }}
                  />
                ))
              ) : (
                <Box key={rowIndex}>
                  <Typography color={'primary.main'} textAlign={'center'}>
                    {t('tableValidation.alert.row')} {rowIndex}
                  </Typography>
                  {tableValidationErrors[rowIndex].map(({ fieldName, fieldLabel, message }) => (
                    <Typography textAlign={'center'} key={`${rowIndex}_${fieldName}_${message}`}>
                      {fieldLabel || fieldName} - {message}
                    </Typography>
                  ))}
                </Box>
              )
            )}
          </Stack>
        ) : null}
      </Box>
    </StyledModal>
  );
}
