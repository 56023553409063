import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SetAlertAndClose } from './AlertReducer';
// api
import cigarUnitApi from '../../api/cigarUnitApi';
import conveyorCigarUnitsApi from '../../api/conveyorCigarUnits';
import cigarUnitProductionApi from '../../api/cigarUnitProductionApi';
// enums
import { LoadingStatusEnum } from '../../constants/enums';

// ----------------------------------------------------------------------------

// THUNKS
export const CreateCigarUnit = createAsyncThunk('cigarUnit/createCigarUnit', async ({ data, qrId }, thunkAPI) => {
  const res = await cigarUnitApi.create(data, qrId);

  if (res.status === 200) {
    thunkAPI.dispatch(SetAlertAndClose({ data: res.data }));

    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const GetCigarUnitByQrId = createAsyncThunk('cigarUnit/getCigarUnitByQrId', async (factory, qrId, thunkAPI) => {
  const res = await cigarUnitApi.getByQrId(factory, qrId);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetCigarUnits = createAsyncThunk('cigarUnit/getCigarUnits', async (data, thunkAPI) => {
  const res = await cigarUnitApi.get(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetAllCigarUnits = createAsyncThunk('cigarUnit/getAllCigarUnits', async (data, thunkAPI) => {
  const res = await cigarUnitApi.getAllCigarUnits(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateCigarUnit = createAsyncThunk('cigarUnit/updateCigarUnit', async ({ data, qrId }, thunkAPI) => {
  const res = await cigarUnitApi.update(data, qrId);

  if (res.status === 200) {
    thunkAPI.dispatch(SetAlertAndClose({ data: res.data }));

    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const GetCigarUnitConveyorCigarUnits = createAsyncThunk(
  'cigarUnit/getCigarUnitConveyorCigarUnits',
  async (id, thunkAPI) => {
    const res = await conveyorCigarUnitsApi.getByCigarUnit(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetCigarUnitProduction = createAsyncThunk('cigarUnit/getCigarUnitProduction', async (id, thunkAPI) => {
  const res = await cigarUnitProductionApi.getByCigarUnitId(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetCigarUnitHistory = createAsyncThunk('cigarUnit/getCigarUnitHistory', async (id, thunkAPI) => {
  const res = await cigarUnitApi.getHistory(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

// state
const initialState = {
  list: [],
  cigarUnitsPeriodEndList: [],
  cigarUnit: {
    _id: null,
    createdAt: null,
    updatedAt: null,
    // props
    type: null,
    quantity: null,
    qrId: null,
    room: null,
    positionOnStand: {
      level: null,
      position: null,
      deep: null,
    },
    status: null,
    isBound: false,
    factory: {},
    cigar: {},
    history: {},
  },
  conveyorCigarUnits: {
    list: [],
    loading: 'idle',
  },
  cigarUnitProduction: {
    list: [],
    loading: 'idle',
  },
  cigarUnitHistory: {
    list: [],
    loading: 'idle',
  },
  cigars: [],
  factoryOptions: {
    quantity: [],
    rooms: [],
    cigarUnitStatuses: [],
    employeesTypes: [],
  },
  history: [],
  employees: [],
  supervisors: [],
  factory: null,
  isBlank: null,
  loading: 'idle',
};

// slice
export const cigarUnitSlice = createSlice({
  name: 'cigarUnit',
  initialState,
  reducers: {
    ClearCigarUnitConveyorCigarUnits: (state) => ({
      ...state,
      conveyorCigarUnits: initialState.conveyorCigarUnits,
    }),
    ClearCigarUnitProduction: (state) => ({
      ...state,
      cigarUnitProduction: initialState.cigarUnitProduction,
    }),
    ClearCigarUnitHistory: (state) => ({
      ...state,
      cigarUnitHistory: initialState.cigarUnitHistory,
    }),
    ResetCigarUnitState: () => initialState,
  },
  extraReducers: (builder) => {
    // ------------------GetCigarUnitByQrId-------------------------
    builder.addCase(GetCigarUnitByQrId.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetCigarUnitByQrId.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetCigarUnitByQrId.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetCigarUnits-------------------------
    builder.addCase(GetCigarUnits.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetCigarUnits.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetCigarUnits.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetAllCigarUnits-------------------------
    builder.addCase(GetAllCigarUnits.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetAllCigarUnits.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetAllCigarUnits.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------CreateCigarUnit-------------------------
    builder.addCase(CreateCigarUnit.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(CreateCigarUnit.fulfilled, (state, action) => {
      const { cigarUnit, history } = action.payload.data;

      return {
        ...state,
        cigarUnit,
        ...(history && {
          history: [history, ...state.history],
        }),
        isBlank: false,
        loading: 'succeeded',
      };
    });

    builder.addCase(CreateCigarUnit.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------UpdateCigarUnit-------------------------
    // builder.addCase(UpdateCigarUnit.pending, (state) => ({
    // 	...state,
    // 	loading: 'pending',
    // }));

    builder.addCase(UpdateCigarUnit.fulfilled, (state, action) => {
      const { cigarUnit, history } = action.payload.data || {};

      return {
        ...state,
        ...(cigarUnit && { cigarUnit }),
        ...(history && {
          history: [history, ...state.history],
        }),
        loading: 'succeeded',
      };
    });

    builder.addCase(UpdateCigarUnit.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetCigarUnitConveyorCigarUnits-------------------------
    builder.addCase(GetCigarUnitConveyorCigarUnits.pending, (state) => ({
      ...state,
      conveyorCigarUnits: {
        ...state.conveyorCigarUnits,
        loading: 'pending',
      },
    }));

    builder.addCase(GetCigarUnitConveyorCigarUnits.fulfilled, (state, action) => ({
      ...state,
      conveyorCigarUnits: {
        list: action.payload.data,
        loading: 'succeeded',
      },
    }));

    builder.addCase(GetCigarUnitConveyorCigarUnits.rejected, (state) => ({
      ...state,
      conveyorCigarUnits: {
        ...state.conveyorCigarUnits,
        loading: 'failed',
      },
    }));

    // ------------------GetCigarUnitProduction-------------------------
    builder.addCase(GetCigarUnitProduction.pending, (state) => ({
      ...state,
      cigarUnitProduction: {
        ...state.cigarUnitProduction,
        loading: 'pending',
      },
    }));

    builder.addCase(GetCigarUnitProduction.fulfilled, (state, action) => ({
      ...state,
      cigarUnitProduction: {
        list: action.payload.data.list,
        loading: 'succeeded',
      },
    }));

    builder.addCase(GetCigarUnitProduction.rejected, (state) => ({
      ...state,
      cigarUnitProduction: {
        ...state.cigarUnitProduction,
        loading: 'failed',
      },
    }));

    // ------------------GetCigarUnitHistory-------------------------
    builder.addCase(GetCigarUnitHistory.pending, (state) => ({
      ...state,
      cigarUnitHistory: {
        ...state.cigarUnitHistory,
        loading: 'pending',
      },
    }));

    builder.addCase(GetCigarUnitHistory.fulfilled, (state, action) => ({
      ...state,
      cigarUnitHistory: {
        list: action.payload.data.list,
        loading: 'succeeded',
      },
    }));

    builder.addCase(GetCigarUnitHistory.rejected, (state) => ({
      ...state,
      cigarUnitHistory: {
        ...state.cigarUnitHistory,
        loading: 'failed',
      },
    }));
  },
});

// Export Actions
export const {
  ClearCigarUnitConveyorCigarUnits,
  ClearCigarUnitProduction,
  ClearCigarUnitHistory,
  ResetCigarUnitState,
} = cigarUnitSlice.actions;

// Export Selectors
export const selectCigarUnits = (state) => state.cigarUnit.list;
export const selectCigarUnitsPeriodEndList = (state) => state.cigarUnit.cigarUnitsPeriodEndList;

export const selectCigarUnitFactory = (state) => state.cigarUnit.factory;
export const selectCigarUnitFactoryOptions = (state) => state.cigarUnit.factoryOptions;
export const selectCigarUnitData = (state) => state.cigarUnit.cigarUnit;

export const selectCigarUnitCigars = (state) => state.cigarUnit.cigars;

export const selectCigarUnitIsBlank = (state) => state.cigarUnit.isBlank;
export const selectCigarUnitLoading = (state) => state.cigarUnit.loading;

export const selectCigarUnitEmployees = (state) => state.cigarUnit.employees;
export const selectCigarUnitSupervisors = (state) => state.cigarUnit.supervisors;

export const selectCigarUnitHistory = (state) => state.cigarUnit.history;

export const selectConveyorCigarUnitsList = (state) => state.cigarUnit.conveyorCigarUnits.list;
export const selectConveyorCigarUnitsLoading = (state) => state.cigarUnit.conveyorCigarUnits.loading;
export const selectConveyorCigarUnitsIsLoading = (state) =>
  state.cigarUnit.conveyorCigarUnits.loading === LoadingStatusEnum.Pending;

export const selectCigarUnitProductionList = (state) => state.cigarUnit.cigarUnitProduction.list;
export const selectCigarUnitProductionLoading = (state) => state.cigarUnit.cigarUnitProduction.loading;
export const selectCigarUnitProductionIsLoading = (state) =>
  state.cigarUnit.cigarUnitProduction.loading === LoadingStatusEnum.Pending;

export const selectCigarUnitHistoryList = (state) => state.cigarUnit.cigarUnitHistory.list;
export const selectCigarUnitHistoryLoading = (state) => state.cigarUnit.cigarUnitHistory.loading;
export const selectCigarUnitHistoryIsLoading = (state) =>
  state.cigarUnit.cigarUnitHistory.loading === LoadingStatusEnum.Pending;

export default cigarUnitSlice.reducer;
