import { createAsyncThunk } from '@reduxjs/toolkit';
// dayjs
import dayjs from 'dayjs';
// api
import tobaccoHumidityRoomProductionsApi from '../../../api/tobaccoHumidityRoomProductionsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'tobaccoHumidityRoomProduction';

export const GetConveyorTobaccoProduction = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorTobaccoHumidityRoomProduction`,
  async (params, thunkAPI) => {
    params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

    const res = await tobaccoHumidityRoomProductionsApi.get(params);

    if (res.status === 200) return { ...res.data?.data, startDate: params.startDate, endDate: params.endDate };

    return thunkAPI.rejectWithValue();
  }
);