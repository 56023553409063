import PropTypes from 'prop-types';
// @mui
import { Box, Stack, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// i18n
import { useTranslation } from 'react-i18next';
// lodash
import _ from 'lodash';
// form
import { useForm, useWatch } from 'react-hook-form';
// components
import { FormProvider, RHFMultiCheckbox, RHFCheckbox } from '../../../../../components/hook-form';
// utils
import getLocalizedText from '../../../../../utils/getLocalizedText';

//-------------------------------------------------------------------------------------------------

const EditUserPermissionsForm = ({ pagePermissionsTemplates, pagePermissions, onUpdate, id }) => {
  const { t, i18n } = useTranslation();

  const defaultValues = _.reduce(
    pagePermissionsTemplates,
    (acc, { page }) => {
      const userPagePermissions = _.find(
        pagePermissions,
        (userPagePermission) => userPagePermission.page?._id === page._id
      );

      return {
        ...acc,
        [page._id]: {
          active: Boolean(userPagePermissions),
          actions: _.map(userPagePermissions?.actions, '_id'),
          views: _.map(userPagePermissions?.views, '_id'),
        },
      };
    },
    {}
  );

  const methods = useForm({
    reValidateMode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const watch = useWatch({ control });

  const formatData = (data) =>
    Object.keys(data).reduce((acc, pageId) => {
      const pagePermissions = data[pageId];

      const { active, actions, views } = pagePermissions;

      if (active) {
        return [
          ...acc,
          {
            page: pageId,
            actions,
            views,
          },
        ];
      }

      return acc;
    }, []);

  const onSubmit = async (data) => {
    const formattedData = formatData(data);

    onUpdate({
      userId: id,
      data: formattedData,
    });
  };

  return (
    <Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            {_.sortBy(
              pagePermissionsTemplates,
              getLocalizedText({ language: i18n.language, en: 'page.title', es: 'page.titleEs' })
            ).map(({ page, actions, views }) => {
              const pageIsActive = watch[`${page._id}`].active === true;

              return (
                <Stack spacing={1} key={page.name}>
                  <RHFCheckbox
                    name={`${page._id}.active`}
                    value={page._id}
                    label={getLocalizedText({ language: i18n.language, en: page.title, es: page.titleEs })}
                    labelProps={{ variant: 'subtitle1', color: pageIsActive ? 'primary.main' : 'text.secondary' }}
                  />

                  {actions?.length > 0 && pageIsActive && (
                    <Box pl={3}>
                      <Typography variant="subtitle2" color={'text.secondary'}>
                        {t('label.actions')}
                      </Typography>
                      <RHFMultiCheckbox
                        name={`${page._id}.actions`}
                        options={_.sortBy(
                          actions,
                          getLocalizedText({ language: i18n.language, en: 'title', es: 'titleEs' })
                        ).map((action) => ({
                          value: action._id,
                          label: getLocalizedText({ language: i18n.language, en: action.title, es: action.titleEs }),
                        }))}
                        labelProps={{ variant: 'body2' }}
                      />
                    </Box>
                  )}

                  {views?.length > 0 && pageIsActive && (
                    <Box pl={3}>
                      <Typography variant="subtitle2" color={'text.secondary'}>
                        {t('label.views')}
                      </Typography>
                      <RHFMultiCheckbox
                        name={`${page._id}.views`}
                        options={_.sortBy(
                          views,
                          getLocalizedText({ language: i18n.language, en: 'title', es: 'titleEs' })
                        ).map((view) => ({
                          value: view._id,
                          label: getLocalizedText({ language: i18n.language, en: view.title, es: view.titleEs }),
                        }))}
                        labelProps={{ variant: 'body2' }}
                      />
                    </Box>
                  )}
                </Stack>
              );
            })}
          </Stack>

          <Divider sx={{ my: 2 }} />
          <Stack alignItems={'flex-end'} direction={'column'}>
            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              {t('button.editPermissions')}
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Box>
  );
};

EditUserPermissionsForm.propTypes = {
  pagePermissionsTemplates: PropTypes.array,
  pagePermissions: PropTypes.array,
  onUpdate: PropTypes.func,
  id: PropTypes.string,
};

export default EditUserPermissionsForm;
