import PropTypes from 'prop-types';
import { useState } from 'react';
// mui
import { Grid, ToggleButton, Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// components
import StyledModal from './StyledModal';
// enums
import { FactoryConfigType } from '../constants/enums';

//----------------------------------------------------------------------

const FactoryConfig = ({ selectedItem, type, isLoading }) => {
  const { t } = useTranslation();

  const { value, name } = selectedItem || {};

  const [openJSONModal, setOpenJSONModal] = useState(false);

  const onOpenJSONModal = () => {
    setOpenJSONModal(true);
  };

  const onCloseJSONModal = () => {
    setOpenJSONModal(false);
  };
  if (isLoading || !name) {
    return <></>;
  }

  return (
    <>
      {type === FactoryConfigType.CommaSeparator && (
        <Grid container direction={'column'}>
          <Typography variant="h6">{t(`authenticationActivityPage.configTitle.${name}`)}</Typography>
          <Grid>{value}</Grid>
        </Grid>
      )}
      {type === FactoryConfigType.JSON && (
        <>
          <ToggleButton onClick={onOpenJSONModal}>{t(`authenticationActivityPage.configTitle.${name}`)}</ToggleButton>

          <StyledModal
            maxWidth={'md'}
            title={t(`authenticationActivityPage.configTitle.${name}`)}
            open={Boolean(openJSONModal)}
            handleClose={onCloseJSONModal}
            isLoading={isLoading}
          >
            {value}
          </StyledModal>
        </>
      )}
      {!type && <Grid>{value}</Grid>}
    </>
  );
};

FactoryConfig.propTypes = {
  selectedItem: PropTypes.object,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default FactoryConfig;
