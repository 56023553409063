// ------------------------------------------------------------------

import _ from 'lodash';

export const getTobaccoLabel = (tobacco) => {
  if (!tobacco) {
    return 'No Tobacco';
  }
  const { code, farm, type, classification, color, size, seed, year } = tobacco;

  const farmText = _.isString(farm) ? ` ${farm}` : farm?.value ? ` ${farm?.value}` : '';
  const typeText = _.isString(type) ? ` ${type}` : type?.value ? ` ${type?.value}` : '';
  const classificationText = _.isString(classification)
    ? ` ${classification}`
    : classification?.value
    ? ` ${classification?.value}`
    : '';
  const colorText = _.isString(color) ? ` ${color}` : color?.value ? ` ${color?.value}` : '';
  const sizeText = _.isString(size) ? ` ${size}` : size?.value ? ` ${size?.value}` : '';
  const seedText = _.isString(seed) ? ` ${seed}` : seed?.value ? ` ${seed?.value}` : '';
  const yearText = _.isString(year) ? ` ${year}` : year?.value ? ` ${year?.value}` : '';

  return `${code}${farmText}${typeText}${classificationText}${colorText}${sizeText}${seedText}${yearText}`;
};
