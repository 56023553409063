import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import conveyorApi from '../../../api/conveyorApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'conveyorTemplates';

export const GetConveyorTemplates = createAsyncThunk(`${REDUCER_NAME}/getConveyorTemplates`, async (__, thunkAPI) => {
  const res = await conveyorApi.getConveyorTemplates();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetConveyorTemplateById = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorTemplateById`,
  async (id, thunkAPI) => {
    const res = await conveyorApi.getConveyorTemplateById(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetConveyorStepTemplateById = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorStepTemplateById`,
  async (id, thunkAPI) => {
    const res = await conveyorApi.getConveyorStepTemplateById(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateConveyorTemplate = createAsyncThunk(
  `${REDUCER_NAME}/updateConveyorTemplate`,
  async ({ id, data }, thunkAPI) => {
    const res = await conveyorApi.update(id, data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateConveyorStepTemplate = createAsyncThunk(
  `${REDUCER_NAME}/updateConveyorStepTemplate`,
  async ({ id, data }, thunkAPI) => {
    const res = await conveyorApi.updateStepTemplate(id, data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const CreateConveyorTemplate = createAsyncThunk(
  `${REDUCER_NAME}/addConveyorTemplate`,
  async (data, thunkAPI) => {
    const res = await conveyorApi.addConveyorTemplate(data);

    if (res.status === 200) return { ...res.data, factory: data.factory };

    return thunkAPI.rejectWithValue();
  }
);

export const ChangeConveyorStepTemplateInactiveStatus = createAsyncThunk(
  `${REDUCER_NAME}/ChangeConveyorTemplateInactiveStatus`,
  async ({ id, status }, thunkAPI) => {
    const res = await conveyorApi.changeInactiveStatus(id, status);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
