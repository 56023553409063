import initialState from './initialState';
import { LoadingStatusEnum } from '../../../constants/enums';

export const CreateTobaccoReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetTobaccosReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: data?.list,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetTobaccoByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const UpdateTobaccoReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const UpdateInactiveStatusReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, data) => {
    const updatedList = state.list.filter(({ _id }) => _id !== data.payload.data._id);
    return {
      ...state,
      list: [...updatedList, data.payload.data],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const ImportTobaccosReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, ...data.list],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetTobaccoOptionsReducer = {
  pending: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const {
      tobaccoFarms,
      tobaccoTypes,
      tobaccoClassifications,
      tobaccoSizes,
      tobaccoSeeds,
      tobaccoColors,
      tobaccoYears,
    } = action.payload;

    return {
      ...state,
      options: {
        data: {
          tobaccoFarms: {
            data: tobaccoFarms?.data || [],
            loading: 'succeeded',
          },
          tobaccoTypes: {
            data: tobaccoTypes?.data || [],
            loading: 'succeeded',
          },
          tobaccoClassifications: {
            data: tobaccoClassifications?.data || [],
            loading: 'succeeded',
          },
          tobaccoSizes: {
            data: tobaccoSizes?.data || [],
            loading: 'succeeded',
          },
          tobaccoSeeds: {
            data: tobaccoSeeds?.data || [],
            loading: 'succeeded',
          },
          tobaccoColors: {
            data: tobaccoColors?.data || [],
            loading: 'succeeded',
          },
          tobaccoYears: {
            data: tobaccoYears?.data || [],
            loading: 'succeeded',
          },
        },
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'failed',
    },
  }),
};

export const CreateFactoryOptionReducer = {
  pending: (state, action) => {
    const { optionName } = action?.meta?.arg || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              ...state.options.data[optionName],
              loading: 'pending',
            },
          }),
        },
      },
    };
  },
  fulfilled: (state, action) => {
    const { data } = action.payload;
    const { optionName } = data || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              data: [...state.options.data[optionName].data, data],
              loading: 'succeeded',
            },
          }),
        },
      },
    };
  },
  rejected: (state, action) => {
    const { optionName } = action?.meta?.arg || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              ...state.options.data[optionName],
              loading: 'failed',
            },
          }),
        },
      },
    };
  },
};

export const GetTobaccoFactoryConfigFormReducer = {
  pending: (state) => ({
    ...state,
    formOptions: {
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    formOptions: {
      options: action.payload,
      loading: LoadingStatusEnum.Succeeded,
    },
  }),
  rejected: (state) => ({
    ...state,
    formOptions: {
      loading: LoadingStatusEnum.Failed,
    },
  }),
};

export const ClearSelectedTobaccoReducer = (state) => ({
  ...state,
  selectedItem: {
    data: null,
    loading: 'idle',
  },
});

export const ResetTobaccosStateReducer = () => initialState;

export const GetTobaccoCostsReducer = {
  pending: (state) => ({
    ...state,
    tobaccoCosts: {
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    tobaccoCosts: {
      data: action.payload.data,
      loading: LoadingStatusEnum.Succeeded,
    },
  }),
  rejected: (state) => ({
    ...state,
    tobaccoCosts: {
      loading: LoadingStatusEnum.Failed,
    },
  }),
};

export const GetSimilarTobaccoCostsReducer = {
  pending: (state) => ({
    ...state,
    similarTobaccosCosts: {
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    similarTobaccosCosts: {
      data: action.payload.data,
      loading: LoadingStatusEnum.Succeeded,
    },
  }),
  rejected: (state) => ({
    ...state,
    similarTobaccosCosts: {
      loading: LoadingStatusEnum.Failed,
    },
  }),
};

export const GetLastCostReducer = {
  pending: (state) => ({
    ...state,
    lastCost: {
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    lastCost: {
      data: action.payload.data,
      loading: LoadingStatusEnum.Succeeded,
    },
  }),
  rejected: (state) => ({
    ...state,
    lastCost: {
      loading: LoadingStatusEnum.Failed,
    },
  }),
};
