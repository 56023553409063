import PropTypes from 'prop-types';
// mui
import { Avatar } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';

// -----------------------------------------------------------------

export const HistoryOperationAvatar = ({ add, index }) => (
  <Avatar sx={add ? { bgcolor: (theme) => theme.palette.primary.main } : {}}>
    {add ? <Iconify icon="eva:plus-fill" width={24} height={24} /> : index}
  </Avatar>
);

HistoryOperationAvatar.propTypes = {
  add: PropTypes.bool,
  index: PropTypes.number,
};
