import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetCigarPerformance, GetPayroll, GetPayrollWarnings } from './thunks';
// reducers
import {
  GetCigarPerformanceReducer,
  ClearCigarPerformanceReducer,
  GetPayrollReducer,
  GetPayrollWarningsReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'cigarPerformance',
  initialState,
  reducers: {
    ClearCigarPerformance: ClearCigarPerformanceReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetCigarPerformance, GetCigarPerformanceReducer);
    addExtraReducer(builder, GetPayroll, GetPayrollReducer);
    addExtraReducer(builder, GetPayrollWarnings, GetPayrollWarningsReducer);
  },
});

export default slice.reducer;
