// i18n
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// date-fns
import {
  format as formatDate,
  formatDistance,
  formatDuration,
  formatRelative,
  intervalToDuration,
  isDate,
} from 'date-fns';
import { enUS as dateEn, es as dateEs } from 'date-fns/locale'; // import all locales we need
// translations
import en from './en/translation.json';
import es from './es/translation.json';

import { convertLanguageJsonToObject } from './translations';

// ----------------------------------------------------------------------------

export const translationsJson = {
  en: {
    translation: en,
    name: 'EN',
    icon: '/static/icons/ic_flag_us.svg',
  },
  es: {
    translation: es,
    name: 'ES',
    icon: '/static/icons/ic_flag_dc.svg',
  },
};

export const availableLanguages = Object.keys(translationsJson).map((item) => ({
  name: translationsJson[item].name,
  locale: item,
  icon: translationsJson[item].icon,
}));

export const dateLocales = {
  en: dateEn,
  es: dateEs,
};

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (isDate(value)) {
          const locale = dateLocales[lng];

          if (format === 'short') return formatDate(value, 'P', { locale });
          if (format === 'shortWithTime') return formatDate(value, 'Pp', { locale });
          if (format === 'shortWithWeekday') {
            return `${formatDate(value, 'EEEE', { locale }).slice(0, 3)} ${formatDate(value, 'P', { locale })}`;
          }
          if (format === 'long') return formatDate(value, 'PPPP', { locale });
          if (format === 'relative') return formatRelative(value, new Date(), { locale });
          if (format === 'ago') {
            return formatDistance(value, new Date(), {
              locale,
              addSuffix: true,
            });
          }
          if (format === 'remaining') {
            const duration = intervalToDuration({
              start: value,
              end: Date.now(),
            });

            return formatDuration(duration, {
              delimiter: ' ',
              // format: ['years', 'months', 'days', 'hours'],
              locale,
            });
          }

          return formatDate(value, format, { locale });
        }

        if (isDate(value.start) && isDate(value.end)) {
          const locale = dateLocales[lng];

          if (format === 'relative') {
            return formatRelative(value.start, value.end, { locale });
          }
          if (format === 'ago') {
            return formatDistance(value.start, value.end, {
              locale,
              addSuffix: true,
            });
          }
          if (format === 'remaining') {
            const duration = intervalToDuration(value);

            return formatDuration(duration, {
              delimiter: ' ',
              // format: ['years', 'months', 'days', 'hours'],
              locale,
            });
          }
        }

        return value;
      },
    },
  });

export default i18n;
