// lodash
import { isObject } from 'lodash';

// --------------------------------------------------------------------------

const populateNext = (data, mode) => {
  const { _id, name, nameEs, icon, conveyorTemplate, next } = data;

  return [
    {
      _id,
      conveyorTemplateId: conveyorTemplate,
      name,
      nameEs,
      icon,
    },
    ...(isObject(next) ? populateNext(next, mode) : []),
  ];
};

export const templatesToStepsArray = (list) =>
  list?.reduce((acc, { firstStep }) => [...acc, ...(firstStep ? populateNext(firstStep) : {})], []);
