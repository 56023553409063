import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetTobaccoInventory,
  GetTobaccoInventoryV2,
  GetTobaccoInventoryRooms,
  GetTobaccoTransactions,
  GetConveyorStepRecords,
  GetAllConveyorStepRecords,
  DeleteTobaccoContainer,
  UpdateTobaccoInventorySubItem,
  GetTobaccoInventorySubItemById,
  GetTobaccoMappingTurnOnStatus,
} from './thunks';
// reducers
import {
  GetTobaccoInventoryReducer,
  GetTobaccoInventoryV2Reducer,
  GetTobaccoInventoryRoomsReducer,
  GetTobaccoTransactionsReducer,
  ClearTobaccoTransactionsReducer,
  GetConveyorStepRecordsReducer,
  GetAllConveyorStepRecordsReducer,
  ClearConveyorStepRecordsReducer,
  ClearAllConveyorStepRecordsReducer,
  DeleteTobaccoContainerReducer,
  UpdateTobaccoInventorySubItemReducer,
  GetTobaccoInventorySubItemByIdReducer,
  ResetTobaccoInventoryStateReducer,
  GetTobaccoMappingTurnOnStatusReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoInventory',
  initialState,
  reducers: {
    ClearConveyorStepRecords: ClearConveyorStepRecordsReducer,
    ClearAllConveyorStepRecords: ClearAllConveyorStepRecordsReducer,
    ClearTobaccoTransactions: ClearTobaccoTransactionsReducer,
    ResetTobaccoInventoryState: ResetTobaccoInventoryStateReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetTobaccoInventory, GetTobaccoInventoryReducer);
    addExtraReducer(builder, GetTobaccoInventoryV2, GetTobaccoInventoryV2Reducer);
    addExtraReducer(builder, GetTobaccoInventoryRooms, GetTobaccoInventoryRoomsReducer);
    addExtraReducer(builder, GetTobaccoTransactions, GetTobaccoTransactionsReducer);
    addExtraReducer(builder, GetConveyorStepRecords, GetConveyorStepRecordsReducer);
    addExtraReducer(builder, GetAllConveyorStepRecords, GetAllConveyorStepRecordsReducer);
    addExtraReducer(builder, DeleteTobaccoContainer, DeleteTobaccoContainerReducer);
    addExtraReducer(builder, GetTobaccoInventorySubItemById, GetTobaccoInventorySubItemByIdReducer);
    addExtraReducer(builder, UpdateTobaccoInventorySubItem, UpdateTobaccoInventorySubItemReducer);
    addExtraReducer(builder, GetTobaccoMappingTurnOnStatus, GetTobaccoMappingTurnOnStatusReducer);
  },
});

export default slice.reducer;
