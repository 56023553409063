import PropTypes from 'prop-types';
// @mui
import { Container, Stack, Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// components
import Page from '../../components/Page';
// sections
import QRCodeData from './DataSections/QRCodeData';
import CigarData from './DataSections/CigarData';

// ----------------------------------------------------------------------

const CigarQrCode = ({ sx, qrCode }) => {
  const { t } = useTranslation();

  return (
    <Page py={12} title={t('pages.qrCodeInfo', { value: t(`qrCodeType.${qrCode.type}`) })}>
      <Container maxWidth={'md'} sx={sx}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h4" gutterBottom>
            {t('pages.qrCodeInfo', { value: t(`qrCodeType.${qrCode.type}`) })}
          </Typography>
          <QRCodeData data={qrCode} />
          <CigarData data={qrCode.cigar} />
        </Stack>
      </Container>
    </Page>
  );
};

CigarQrCode.propTypes = {
  sx: PropTypes.object,
  qrCode: PropTypes.object,
};

export default CigarQrCode;
