import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import employeeTeamsApi from '../../../api/employeeTeamsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'employeeTeams';

// export const CreateTobacco = createAsyncThunk(`${REDUCER_NAME}/createTobacco`, async (data, thunkAPI) => {
//   const res = await tobaccosApi.create(data);

//   if (res.status === 200) return res.data;

//   return thunkAPI.rejectWithValue();
// });

export const GetEmployeeTeams = createAsyncThunk(`${REDUCER_NAME}/getEmployeeTeams`, async (__, thunkAPI) => {
  const res = await employeeTeamsApi.getV2();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

// export const GetTobaccoById = createAsyncThunk(`${REDUCER_NAME}/getTobaccoById`, async (id, thunkAPI) => {
//   const res = await tobaccosApi.getById(id);

//   if (res.status === 200) return res.data;

//   return thunkAPI.rejectWithValue();
// });

// export const UpdateTobacco = createAsyncThunk(`${REDUCER_NAME}/updateTobacco`, async ({ data, id }, thunkAPI) => {
//   const res = await tobaccosApi.update(data, id);

//   if (res.status === 200) return res.data;

//   return thunkAPI.rejectWithValue();
// });

// export const ImportUsers = createAsyncThunk(`${REDUCER_NAME}/importUsers`, async (data, thunkAPI) => {
//   const res = await distributorApi.importUsers(data);

//   if (res.status === 200) return res.data;

//   return thunkAPI.rejectWithValue();
// });

export const UpdateEmployeeTeam = createAsyncThunk(
  `${REDUCER_NAME}/updateEmployeeTeam`,
  async (updateTeamRequest, thunkAPI) => {
    const res = await employeeTeamsApi.updateV2(updateTeamRequest);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue(res.data);
  }
);

export const updateEmployeeTeamWorkingOnCigar = createAsyncThunk(
  `${REDUCER_NAME}/updateWorkingOnCigar`,
  async ({ teamId, workOnCigarNew }, thunkAPI) => {
    const res = employeeTeamsApi.updateTeamWorkingOnCigar({ id: teamId, workOnCigar: workOnCigarNew });

    if (res.ok) return res.json();

    return { error: thunkAPI.rejectWithValue(res.status) };
  }
);
