const initialState = {
  list: [],
  loading: 'idle',

  selectedItem: {
    data: null,
    loading: 'idle',
  },

  result: {
    data: null,
    loading: 'idle',
  },

  pending: {
    data: [],
    loading: 'idle',
  },
};

export default initialState;
