import PropTypes from 'prop-types';
// mui
import { Box, TableSortLabel, Skeleton, Tooltip, Stack } from '@mui/material';
// components
import TableCell from './TableCell';
import Iconify from '../../../components/Iconify';
// hooks
import useAccess from '../../../hooks/useAccess';
import { useTableContext } from '../../../providers/TableProvider';

// ----------------------------------------------------------------------------

export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// -----------------------------------------------------------------------------

const TableHeadCell = ({ size, data, orderBy, order, createSortHandler, isLoading }) => {
  const { id, label, info, align, alignRight, disableSort, colSpan, noAccessByDefault } = data;

  const alignment = align || (alignRight ? 'right' : 'left');

  const tableContext = useTableContext();
  const { permissionPrefix } = tableContext || {};

  const hasAccess = useAccess(`${permissionPrefix}.views.${id}`);

  const HeadLabel = (
    <Tooltip title={info || ''}>
      <TableSortLabel
        hideSortIcon
        active={disableSort ? false : orderBy === id}
        direction={orderBy === id ? order : 'asc'}
        onClick={disableSort ? null : createSortHandler(id)}
      >
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
          <span>{label}</span>
          {info && (
            <Iconify
              icon={'material-symbols:info-outline'}
              sx={{ ml: 0.3, width: 15, height: 15, color: (theme) => theme.palette.text.secondary }}
            />
          )}
        </Stack>
        {orderBy === id ? (
          <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
        ) : null}
      </TableSortLabel>
    </Tooltip>
  );

  if (!noAccessByDefault || hasAccess) {
    return (
      <TableCell
        size={size}
        bold
        align={alignment}
        sortDirection={orderBy === id ? order : false}
        {...(colSpan && { colSpan })}
      >
        {isLoading ? (
          <Box width={'100%'} display={'flex'} justifyContent={alignment}>
            <Skeleton>{HeadLabel}</Skeleton>
          </Box>
        ) : (
          HeadLabel
        )}
      </TableCell>
    );
  }

  return null;
};

TableHeadCell.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  createSortHandler: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

export default TableHeadCell;
