import PropTypes from 'prop-types';
import { useState } from 'react';
// material
import { Card, Table, TableRow, TableBody, TableContainer } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// date-fns
import { parseISO } from 'date-fns';
// components
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { TableHead, TableToolbar, TableCell, TableMoreMenu } from '../table';
// utils
import { getComparator, applySortFilter } from '../../../utils/tableUtils';
import getLocalizedText from '../../../utils/getLocalizedText';
// enums
import { LoadingStatusEnum } from '../../../constants/enums';
import TableMoreMenuItem from '../table/TableMoreMenu/TableMoreMenuItem';
import { TableToolbarIconButton } from '../table/TableToolbar';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'title', label: t('label.title'), alignRight: false },
  { id: 'message', label: t('label.message'), alignRight: false },
  { id: 'isRead', label: t('label.read'), alignRight: false },
  { id: 'createdAt', label: t('label.created'), alignRight: false },
  { id: 'actions' },
];

// ----------------------------------------------------------------------

const getFilteredItem = (item, t) => {
  const translatedTitle = getLocalizedText({ en: item.title, es: item.titleEs });
  const translatedMessage = getLocalizedText({ en: item.message, es: item.messageEs });

  return {
    title: translatedTitle,
    message: translatedMessage,
    isRead: item.isRead ? t('label.read') : t('label.unread'),
    createdAt: t('date.shortWithTime', { date: parseISO(item.createdAt) }),
  };
};

export default function UserNotificationsTable({ list, loading, onMarkAsRead, onMarkAsUnRead, onMarkAllAsRead, sx }) {
  const { t } = useTranslation();

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const totalUnRead = list.filter((item) => item.isRead === false).length;

  const filteredItems = applySortFilter(list, getComparator(order, orderBy), filterName, (item) =>
    getFilteredItem(item, t)
  );

  const isItemNotFound = filteredItems.length === 0;

  const isLoading = loading === LoadingStatusEnum.Pending || loading === LoadingStatusEnum.Idle;

  return (
    <Card sx={sx}>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName}>
        <TableToolbarIconButton
          text={t('notifications.markAllAsRead')}
          onClick={onMarkAllAsRead}
          disabled={totalUnRead === 0 || isLoading}
          icon="eva:done-all-fill"
        />
      </TableToolbar>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={getTableHead(t)}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredItems.map((row) => {
                const { _id, title, titleEs, message, messageEs, isRead, createdAt } = row;

                const translatedTitle = getLocalizedText({ en: title, es: titleEs });
                const translatedMessage = getLocalizedText({ en: message, es: messageEs });

                return (
                  <TableRow key={_id}>
                    <TableCell isLoading={isLoading} align="left">
                      {translatedTitle}
                    </TableCell>
                    <TableCell isLoading={isLoading} align="left">
                      {translatedMessage}
                    </TableCell>
                    <TableCell isLoading={isLoading} align="left" color={isRead ? 'success.main' : 'error.main'}>
                      {isRead ? t('label.read') : t('label.unread')}
                    </TableCell>
                    <TableCell isLoading={isLoading} align="left">
                      {t('date.shortWithTime', { date: parseISO(createdAt) })}
                    </TableCell>
                    <TableCell align="right" isLoading={isLoading}>
                      <TableMoreMenu>
                        {onMarkAsRead && !isRead && (
                          <TableMoreMenuItem onClick={() => onMarkAsRead(_id)} icon={'bi:eye'} color={'success.main'}>
                            {t('label.read')}
                          </TableMoreMenuItem>
                        )}
                        {onMarkAsUnRead && isRead && (
                          <TableMoreMenuItem
                            onClick={() => onMarkAsUnRead(_id)}
                            icon={'bi:eye-slash'}
                            color={'error.main'}
                          >
                            {t('label.unread')}
                          </TableMoreMenuItem>
                        )}
                      </TableMoreMenu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            {isItemNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} isLoading={isLoading} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

UserNotificationsTable.propTypes = {
  loading: PropTypes.string,
  list: PropTypes.array,
  onMarkAsRead: PropTypes.func,
  onMarkAsUnRead: PropTypes.func,
  onMarkAllAsRead: PropTypes.func,
  sx: PropTypes.object,
};
