import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { CreateRoom, GetRooms, GetInventoryRooms, UpdateRoom } from './thunks';
// reducers
import {
  CreateRoomReducer,
  GetRoomsReducer,
  GetInventoryRoomsReducer,
  UpdateRoomReducer,
  ResetRoomsStateReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const inventorySlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    ResetRoomsState: ResetRoomsStateReducer,
  },
  extraReducers: (builder) => {
    // CreateRoom
    addExtraReducer(builder, CreateRoom, CreateRoomReducer);
    // GetRooms
    addExtraReducer(builder, GetRooms, GetRoomsReducer);
    // GetInventoryRooms
    addExtraReducer(builder, GetInventoryRooms, GetInventoryRoomsReducer);
    // UpdateRoom
    addExtraReducer(builder, UpdateRoom, UpdateRoomReducer);
  },
});

export default inventorySlice.reducer;
