const initialState = {
  selectedItem: null,
  list: [],
  suppliers: [],
  rawMaterials: [],

  selectedItemLoading: 'idle',
  suppliersLoading: 'idle',
  rawMaterialsLoading: 'idle',

  loading: 'idle',
};

export default initialState;
