import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export default {
  list: (state) => state.tobaccos.list,
  loading: (state) => state.tobaccos.loading,
  isLoading: (state) => state.tobaccos.loading === LoadingStatusEnum.Pending,

  selectedItem: {
    data: (state) => state.tobaccos.selectedItem.data,
    loading: (state) => state.tobaccos.selectedItem.loading,
    isLoading: (state) => state.tobaccos.selectedItem.loading === LoadingStatusEnum.Pending,
  },

  options: {
    data: (state) => state.tobaccos.options.data,
    loading: (state) => state.tobaccos.options.loading,
    isLoading: (state) => state.tobaccos.options.loading === LoadingStatusEnum.Pending,
  },

  formOptions: {
    options: (state) => state.tobaccos.formOptions.options,
    isLoading: (state) => state.tobaccos.formOptions.loading === LoadingStatusEnum.Pending,
  },

  tobaccoCosts: {
    data: (state) => state.tobaccos.tobaccoCosts.data,
    loading: (state) => state.tobaccos.tobaccoCosts.loading,
  },

  similarTobaccosCosts: {
    data: (state) => state.tobaccos.similarTobaccosCosts.data,
    loading: (state) => state.tobaccos.similarTobaccosCosts.loading,
  },

  lastCost: {
    data: (state) => state.tobaccos.lastCost.data,
    loading: (state) => state.tobaccos.lastCost.loading,
  },
};
