import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
// i18n
import { useTranslation } from 'react-i18next';
// react-redux
import { useSelector } from 'react-redux';
import { selectFormHasUnsavedChanges } from '../redux/reducers/SessionReducer';
// components
import Iconify from './Iconify';
import Scrollbar from './Scrollbar';
import ConfirmationDialog from './ConfirmationDialog';
// hooks
import useDisclosure from '../hooks/useDisclosure';
// import { useCallbackPrompt } from '../hooks/useCallbackPrompt';

// ---------------------------------------------------------------

export default function StyledModal({
  open,
  handleClose,
  title,
  subtitle,
  hideCloseButton = false,
  maxWidth,
  fullScreen,
  isLoading,
  children = null,
  showCloseModalWithUnsavedDataConfirmation,
  withoutScroll,
  sx,
  ...rest
}) {
  const { t } = useTranslation();

  const fixedHeader = title || subtitle || !hideCloseButton;

  const formHasUnsavedChanges = useSelector(selectFormHasUnsavedChanges);

  // useCallbackPrompt(showCloseModalWithUnsavedDataConfirmation && formHasUnsavedChanges);

  const {
    isOpen: isSaveConfirmationOpen,
    open: onSaveConfirmationOpen,
    close: onSaveConfirmationClose,
  } = useDisclosure();

  const onCloseAllModals = () => {
    handleClose();
    onSaveConfirmationClose();
  };

  const onClose = () => {
    if (showCloseModalWithUnsavedDataConfirmation && formHasUnsavedChanges) {
      onSaveConfirmationOpen();
    } else {
      onCloseAllModals();
    }
  };

  return (
    <>
      <Dialog
        maxWidth={maxWidth || 'lg'}
        fullWidth
        onClose={onClose}
        open={open}
        sx={{ ...sx, zIndex: '1300 !important', height: '100%' }}
        fullScreen={fullScreen}
        {...rest}
      >
        {fixedHeader && (
          <>
            <Stack direction={'row'} p={2} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
              <Stack direction={'column'}>
                {title && <Typography variant="h4">{title}</Typography>}
                {subtitle && (
                  <Typography variant="subtitle" color={'text.secondary'}>
                    {subtitle}
                  </Typography>
                )}
              </Stack>
              {!title && !subtitle && <Box />}
              {!hideCloseButton && (
                <Box display={'flex'} justifyContent={'end'}>
                  <IconButton onClick={onClose} color={'primary'}>
                    <Iconify icon={'carbon:close-outline'} width={30} height={30} />
                  </IconButton>
                </Box>
              )}
            </Stack>
            <Divider />
          </>
        )}
        {isLoading ? (
          <Box py={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <CircularProgress disableShrink />
          </Box>
        ) : children ? (
          withoutScroll ? (
            <Box sx={{ p: 2, maxHeight: fullScreen ? '100%' : '80vh' }}>{children}</Box>
          ) : (
            <Scrollbar sx={{ p: 2, maxHeight: fullScreen ? '100%' : '80vh' }}>{children}</Scrollbar>
          )
        ) : null}
      </Dialog>
      <ConfirmationDialog
        sx={{ zIndex: '1350 !important' }}
        open={isSaveConfirmationOpen}
        onAgree={onCloseAllModals}
        onDisagree={onSaveConfirmationClose}
        onClose={onSaveConfirmationClose}
        text={t('dialog.confirmation.closeModalWithUnsavedData.text')}
        disagreeText={t('button.cancel')}
        agreeText={t('dialog.confirmation.closeModalWithUnsavedData.agree')}
        agreeColor={'error'}
      />
    </>
  );
}

StyledModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  maxWidth: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  withoutScroll: PropTypes.bool,
  showCloseModalWithUnsavedDataConfirmation: PropTypes.bool,
  sx: PropTypes.object,
};
