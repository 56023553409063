import { Fragment, useEffect } from 'react';
// @mui
import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// react-redux
import { useDispatch, useSelector } from 'react-redux';
// i18n
import { useTranslation } from 'react-i18next';
// lodash
import _ from 'lodash';
// redux
import selectors from '../redux/reducers/dashboard/selectors';
import { GetTobaccosInStock, GetTotalStats } from '../redux/reducers/dashboard/thunks';
// components
import Page from '../components/Page';
// sections
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  CigarPerformanceCostsChart,
  ClassifiedTobaccoCostChart,
  GiftChart,
  ProductionChart,
  TobaccoClassificationChart,
  TobaccoCostChart,
} from '../sections/@dashboard/app';
// utils
import getLocalizedText from '../utils/getLocalizedText';
// hooks
import useAccess from '../hooks/useAccess';
import { ResetDashboardState } from '../redux/reducers/dashboard/actions';

import { moneyFormat } from '../utils/formatNumber';

// ----------------------------------------------------------------------

const SUMMARY_WIDGET_COLORS = ['primary', 'secondary', 'info', 'warning'];

export default function DashboardApp() {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // const data = [
  //   { timestamp: '2023-06-01T12:00:00Z', price: 10 },
  //   { timestamp: '2023-06-02T12:00:00Z', price: 12 },
  //   { timestamp: '2023-06-03T12:00:00Z', price: 15 },
  //   // Add more data entries as needed
  // ];

  // redux data
  const tobaccosInStock = useSelector(selectors.tobaccosInStock.data);
  const tobaccosInStockIsLoading = useSelector(selectors.tobaccosInStock.isLoading);

  const totalStats = useSelector(selectors.totalStats.data);
  const totalStatsIsLoading = useSelector(selectors.totalStats.isLoading);

  useEffect(() => {
    dispatch(GetTotalStats());
    dispatch(GetTobaccosInStock());

    return () => {
      dispatch(ResetDashboardState());
    };
  }, [dispatch]);

  // permissions

  const seeTobaccoStats = useAccess('dashboard.views.tobaccosStats');
  const seeCigarPerformanceCosts = useAccess('dashboard.views.cigarPerformanceCosts');
  const seeClassifiedTobaccoCosts = useAccess('dashboard.views.classifiedTobaccoCosts');
  const seeTobaccoCosts = useAccess('dashboard.views.tobaccoCosts');
  const seeTobaccoClassification = useAccess('dashboard.views.tobaccoClassification');
  const seeGiftPerformance = useAccess('dashboard.views.giftPerformance');
  const seeCigarProductionPerformance = useAccess('dashboard.views.cigarProductionPerformance');
  const seeCigarProduction = useAccess('dashboard.views.cigarProduction');
  const seeCigarStatus = useAccess('dashboard.views.cigarStatus');

  return (
    <Page title={t('pages.dashboard')} isLoading={tobaccosInStockIsLoading || totalStatsIsLoading}>
      <Container maxWidth="false">
        {/* <Typography variant="h4" sx={{ mb: 2 }}>
          Hi, Welcome back
        </Typography> */}
        {/* <Grid container spacing={2} style={{ height: '373.5px', width: '100vw' }}>
          <Grid item xs={12}>
            <iframe
              title="Report Section"
              width="80%"
              height="100%"
              src="https://app.powerbi.com/view?r=eyJrIjoiYTNiNzhmYmUtNWY2My00MjM0LTg5N2MtMTk5YTBjMmQ1ZDI1IiwidCI6ImU1MGE2YTg2LTI0ZTAtNDdlNy04YWE4LWVlNjViOGRhYWM4ZiIsImMiOjl9"
              allowFullScreen="true"
            />
          </Grid>
        </Grid> */}
        {/* {true && (
            <Grid item xs={12} md={6} lg={12}>
              <PowerBIReport title="Power BI report" />
            </Grid>
          )} */}

        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Blends" total={574} icon={'ant-design:sketch-circle-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Employees" total={412} color="info" icon={'ant-design:skin-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Rolled Cigars" total={172331} color="warning" icon={'ant-design:rocket-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Balance RD$"
              total={995435}
              color="error"
              icon={'ant-design:dollar-circle-filled'}
            />
          </Grid> */}

          {totalStats?.length > 0 &&
            totalStats?.map(({ title, titleEs, items }) => {
              const sectionTitle = getLocalizedText({ language: i18n.language, en: title, es: titleEs });

              return (
                <Fragment key={title}>
                  <Grid item xs={12}>
                    <Typography variant="h4" mb={-1}>
                      {sectionTitle}
                    </Typography>
                  </Grid>

                  <Grid container item spacing={2} xs={12}>
                    {items?.map(({ property, propertyEs, value, icon }, idx) => {
                      const color = _.nth(SUMMARY_WIDGET_COLORS, idx % SUMMARY_WIDGET_COLORS.length);

                      const title = getLocalizedText({ language: i18n.language, en: property, es: propertyEs });

                      // console.log(property)

                      return (
                        <Grid item xs={6} md={3} xl={1.5} key={property}>
                          <AppWidgetSummary total={value} title={title} icon={icon} color={color} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Fragment>
              );
            })}

          {(seeTobaccoStats ||
            seeClassifiedTobaccoCosts ||
            seeTobaccoCosts ||
            seeTobaccoClassification ||
            seeGiftPerformance) && (
            <Grid item xs={12}>
              <Typography variant="h4" mb={-1}>
                {t('pages.tobaccos')}
              </Typography>
            </Grid>
          )}

          {seeTobaccoStats && tobaccosInStock?.length > 0 && (
            <Grid container item spacing={2} xs={12}>
              {tobaccosInStock?.map(({ _id, type, totalWeight, totalCost }) => (
                <Grid item xs={6} md={3} xl={1.5} key={_id}>
                  <AppWidgetSummary
                    text={type}
                    title={`${moneyFormat(totalWeight || 0)} lb / $${moneyFormat(totalCost || 0)}`}
                    color="primary"
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {seeClassifiedTobaccoCosts && (
            <Grid item xs={12} md={6} lg={12}>
              <ClassifiedTobaccoCostChart title="Classified Tobacco Costs" />
            </Grid>
          )}

          {seeTobaccoCosts && (
            <Grid item xs={12} md={6} lg={12}>
              <TobaccoCostChart title="Tobacco Costs" />
            </Grid>
          )}

          {seeTobaccoClassification && (
            <Grid item xs={12} md={6} lg={12}>
              <TobaccoClassificationChart title="Tobacco Classification" />
            </Grid>
          )}

          {seeGiftPerformance && (
            <Grid item xs={12} md={6} lg={12}>
              <GiftChart title="Gift Performance" />
            </Grid>
          )}

          {(seeCigarPerformanceCosts || seeCigarProductionPerformance || seeCigarProduction || seeCigarStatus) && (
            <Grid item xs={12}>
              <Typography variant="h4" mb={-1}>
                {t('pages.cigars')}
              </Typography>
            </Grid>
          )}

          {seeCigarPerformanceCosts && (
            <Grid item xs={12} md={6} lg={12}>
              <CigarPerformanceCostsChart title="Cigar Performance Costs" />
            </Grid>
          )}

          {seeCigarProductionPerformance && (
            <Grid item xs={12} md={6} lg={12}>
              <ProductionChart title="Production Performance" />
            </Grid>
          )}

          {seeCigarProduction && (
            <Grid item xs={12} md={6} lg={8}>
              <AppWebsiteVisits
                title="Cigar Production"
                subheader="(+43%) than last year"
                chartLabels={[
                  '01/01/2003',
                  '02/01/2003',
                  '03/01/2003',
                  '04/01/2003',
                  '05/01/2003',
                  '06/01/2003',
                  '07/01/2003',
                  '08/01/2003',
                  '09/01/2003',
                  '10/01/2003',
                  '11/01/2003',
                ]}
                chartData={[
                  {
                    name: 'Grade A',
                    type: 'column',
                    fill: 'solid',
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                  },
                  {
                    name: 'Grade B',
                    type: 'area',
                    fill: 'gradient',
                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                  },
                  {
                    name: 'Grade C',
                    type: 'line',
                    fill: 'solid',
                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                  },
                ]}
              />
            </Grid>
          )}

          {seeCigarStatus && (
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentVisits
                title="Cigar Status"
                chartData={[
                  { label: 'Aged', value: 4344 },
                  { label: 'Aging', value: 5435 },
                  { label: 'Packed', value: 1443 },
                  { label: 'Rolled', value: 4443 },
                ]}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.chart.blue[0],
                  theme.palette.chart.violet[0],
                  theme.palette.chart.yellow[0],
                ]}
              />
            </Grid>
          )}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
