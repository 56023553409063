import PropTypes from 'prop-types';
import { useState } from 'react';
// material ui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableContainer,
  // Badge, Tooltip, IconButton, Box
} from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// date fns
import { parseISO } from 'date-fns';
// components
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { TableHead, TableToolbar, TableCell, TableMoreMenu } from '../table';
// import Iconify from '../../../components/Iconify';
// utils
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
// emuns
import { AuthenticationActivityStatusEnum, LoadingStatusEnum } from '../../../constants/enums';
import TableMoreMenuItem from '../table/TableMoreMenu/TableMoreMenuItem';
// hooks
import useAccess from '../../../hooks/useAccess';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'user.fullName', label: t('label.user'), alignRight: false },
  { id: 'device', label: t('label.device'), alignRight: false },
  { id: 'ip', label: t('label.ip'), alignRight: false },
  { id: 'status', label: t('label.status'), alignRight: false },
  // { id: 'authenticationAttempts', label: t('label.authenticationAttempts'), alignRight: false },
  { id: 'lastAuthenticationAttempt', label: t('label.lastAuthenticationAttempt'), alignRight: false },
  { id: 'updatedAt', label: t('table.updated'), alignRight: false },
  { id: 'createdAt', label: t('table.created'), alignRight: false },
  { id: '' },
];

const getFilteredItem = (item, t) => ({
  device: item.device,
  ip: item.ip,
  user: item.user?.fullName || '',
  status: t(`authenticationActivityStatus.${item.status}`),
  lastAuthenticationAttempt: item.lastAuthenticationAttempt
    ? ('date.shortWithTime', { date: parseISO(item.lastAuthenticationAttempt) })
    : '',
  updatedAt: t('date.shortWithTime', { date: parseISO(item.updatedAt) }),
  createdAt: t('date.shortWithTime', { date: parseISO(item.createdAt) }),
});

// ----------------------------------------------------------------------

export default function AuthenticationActivityTable({
  list,
  loading,
  onUpdateStatus,
  //  onEdit
}) {
  const { t } = useTranslation();

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const tableHead = getTableHead(t);

  const filteredList = applySortFilter(list, getComparator(order, orderBy), filterName, (item) =>
    getFilteredItem(item, t)
  );

  const isItemNotFound = filteredList.length === 0;

  const isLoading = loading === LoadingStatusEnum.Pending || loading === LoadingStatusEnum.Idle;

  const canApprove = useAccess('authenticationActivity.actions.approve');
  const canDecline = useAccess('authenticationActivity.actions.decline');

  return (
    <Card>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredList.map((row) => {
                const {
                  _id,
                  device,
                  ip,
                  user,
                  status,
                  lastAuthenticationAttempt,
                  // authenticationAttempts,
                  updatedAt,
                  createdAt,
                } = row;

                return (
                  <TableRow hover key={_id}>
                    <TableCell
                      align="left"
                      isLoading={isLoading}
                      // onClick={() => onEdit(_id)}
                      sx={{ width: { xs: '50px', sm: '150px', md: 'auto' } }}
                    >
                      {user?.fullName || ''}
                    </TableCell>
                    <TableCell align="left" isLoading={isLoading}>
                      {device}
                    </TableCell>
                    <TableCell align="left" isLoading={isLoading}>
                      {ip}
                    </TableCell>

                    <TableCell
                      align="left"
                      isLoading={isLoading}
                      color={
                        status === AuthenticationActivityStatusEnum.Approved ||
                        status === AuthenticationActivityStatusEnum.AutoApproved
                          ? 'success.main'
                          : status === AuthenticationActivityStatusEnum.Declined
                          ? 'error.main'
                          : 'text.secondary'
                      }
                    >
                      {t(`authenticationActivityPage.status.${status}`)}
                    </TableCell>
                    {/* <TableCell align="left" isLoading={isLoading}>
                      {authenticationAttempts?.length > 0 && (
                        <Tooltip
                          title={
                            <Box style={{ whiteSpace: 'pre-line' }}>
                              {authenticationAttempts
                                .map((authenticationAttempt) =>
                                  t('date.shortWithTime', { date: parseISO(authenticationAttempt) })
                                )
                                .join('\n')}
                            </Box>
                          }
                        >
                          <IconButton>
                            <Badge color="primary" badgeContent={authenticationAttempts.length}>
                              <Iconify icon={'material-symbols:description-rounded'} />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell> */}
                    <TableCell align="left" isLoading={isLoading}>
                      {lastAuthenticationAttempt
                        ? t('date.shortWithTime', { date: parseISO(lastAuthenticationAttempt) })
                        : ''}
                    </TableCell>
                    <TableCell align="left" isLoading={isLoading}>
                      {t('date.shortWithTime', { date: parseISO(updatedAt) })}
                    </TableCell>
                    <TableCell align="left" isLoading={isLoading}>
                      {t('date.shortWithTime', { date: parseISO(createdAt) })}
                    </TableCell>
                    <TableCell align="right" isLoading={isLoading}>
                      <TableMoreMenu
                      // onEdit={() => onEdit(_id)}
                      >
                        {canApprove && status !== AuthenticationActivityStatusEnum.Approved && (
                          <TableMoreMenuItem
                            onClick={() => onUpdateStatus(AuthenticationActivityStatusEnum.Approved, _id)}
                            icon={'material-symbols:check-circle-outline'}
                            color={'success.main'}
                          >
                            {t('button.approve')}
                          </TableMoreMenuItem>
                        )}
                        {canDecline && status !== AuthenticationActivityStatusEnum.Declined && (
                          <TableMoreMenuItem
                            onClick={() => onUpdateStatus(AuthenticationActivityStatusEnum.Declined, _id)}
                            icon={'material-symbols:cancel-outline'}
                            color={'error.main'}
                          >
                            {t('button.decline')}
                          </TableMoreMenuItem>
                        )}
                      </TableMoreMenu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            {isItemNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={tableHead.length} sx={{ py: 3 }}>
                    <SearchNotFound isLoading={isLoading} searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

AuthenticationActivityTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.string,
  onUpdateStatus: PropTypes.func,
  // onEdit: PropTypes.func,
};
