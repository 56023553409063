import { LoadingStatusEnum } from '../../../constants/enums';

const initialState = {
  list: [],
  loading: 'idle',

  selectedItem: {
    data: null,
    loading: 'idle',
  },

  options: {
    data: {
      tobaccoFarms: {
        data: [],
        loading: 'idle',
      },
      tobaccoTypes: {
        data: [],
        loading: 'idle',
      },
      tobaccoClassifications: {
        data: [],
        loading: 'idle',
      },
      tobaccoSizes: {
        data: [],
        loading: 'idle',
      },
      tobaccoSeeds: {
        data: [],
        loading: 'idle',
      },
      tobaccoColors: {
        data: [],
        loading: 'idle',
      },
      tobaccoYears: {
        data: [],
        loading: 'idle',
      },
    },
    loading: 'idle',
  },

  formOptions: {
    options: {},
    loading: LoadingStatusEnum.Idle,
  },

  tobaccoCosts: {
    data: [],
    loading: LoadingStatusEnum.Idle,
  },

  similarTobaccosCosts: {
    data: [],
    loading: LoadingStatusEnum.Idle,
  },

  lastCost: {
    data: null,
    loading: LoadingStatusEnum.Idle,
  },
};

export default initialState;
