import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import cycleCountApi from '../../../api/cycleCountApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'cycleCounts';

export const CreateCycleCount = createAsyncThunk(`${REDUCER_NAME}/createCycleCount`, async (data, thunkAPI) => {
  const res = await cycleCountApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetCycleCounts = createAsyncThunk(`${REDUCER_NAME}/getCycleCounts`, async (__, thunkAPI) => {
  const res = await cycleCountApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetCycleCountResult = createAsyncThunk(
  `${REDUCER_NAME}/getCycleCountResult`,
  async (cycleCountRequestId, thunkAPI) => {
    const res = await cycleCountApi.getResult(cycleCountRequestId);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetPendingCycleCountRequests = createAsyncThunk(
  `${REDUCER_NAME}/getPendingCycleCountRequests`,
  async (__, thunkAPI) => {
    const res = await cycleCountApi.getPendingCycleCountRequests();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetCycleCountById = createAsyncThunk(`${REDUCER_NAME}/getCycleCountById`, async (id, thunkAPI) => {
  const res = await cycleCountApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateCycleCount = createAsyncThunk(`${REDUCER_NAME}/updateCycleCount`, async ({ data, id }, thunkAPI) => {
  const res = await cycleCountApi.update(data, id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const ChangeCycleCountStatus = createAsyncThunk(
  `${REDUCER_NAME}/changeCycleCount`,
  async ({ data, id }, thunkAPI) => {
    const res = await cycleCountApi.changeStatus(data, id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const ApplyActualQuantity = createAsyncThunk(
  `${REDUCER_NAME}/applyActualQuantity`,
  async ({ id, actualQuantity }, thunkAPI) => {
    const res = await cycleCountApi.applyActualQuantity(id, actualQuantity);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const BatchApplyActualQuantity = createAsyncThunk(
  `${REDUCER_NAME}/batchApplyActualQuantity`,
  async ({ ids }, thunkAPI) => {
    const res = await cycleCountApi.batchApplyActualQuantity(ids);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateCycleCountResult = createAsyncThunk(
  `${REDUCER_NAME}/updateCycleCountResult`,
  async ({ data, id }, thunkAPI) => {
    const res = await cycleCountApi.updateResult(data, id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const RepeatRequest = createAsyncThunk(`${REDUCER_NAME}/repeatRequest`, async (id, thunkAPI) => {
  const res = await cycleCountApi.repeatRequest(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const ResetRequest = createAsyncThunk(`${REDUCER_NAME}/resetRequest`, async (id, thunkAPI) => {
  const res = await cycleCountApi.resetRequest(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
