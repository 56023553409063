import PropTypes from 'prop-types';
// mui
import { Typography } from '@mui/material';

// -------------------------------------------------------------------------------

const TableTotalWithPrice = ({ total = 0, totalPrice = 0, showPrice, size }) => {
  let bodyVariant = 'body2';

  switch (size) {
    case 'xs':
      bodyVariant = 'body4';
      break;
    case 'sm':
      bodyVariant = 'body3';
      break;
    default:
      bodyVariant = 'body2';
      break;
  }

  // const _currencySymbol = currencySymbol || CurrenciesSymbolsEnum[currency] || CurrenciesSymbolsEnum.USD;

  return (
    <Typography variant={bodyVariant} color={'text.secondary'} align="center">
      {total && totalPrice ? `${total.toFixed(2)} ${showPrice ? `/ ${totalPrice.toFixed(2)}` : ''}` : '0.00'}
    </Typography>
  );
};

TableTotalWithPrice.propTypes = {
  total: PropTypes.number,
  totalPrice: PropTypes.number,
  showPrice: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  // currency: PropTypes.oneOf(['USD', 'DOP']),
  // currencySymbol: PropTypes.string,
};

export default TableTotalWithPrice;
