import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axiosApiInstance } from '../../../../../api/axios';
import { GetTobaccos } from '../../../../../redux/reducers/tobaccos/thunks';
import tobaccosSelectors from '../../../../../redux/reducers/tobaccos/selectors';

export const useData = ({ tobaccos, isSynchronized }) => {
  /**
   * States
   */
  const [list, setList] = useState([]);

  /**
   * Hooks
   */
  const dispatch = useDispatch();

  /**
   * Values
   */
  const tobaccosList = useSelector(tobaccosSelectors.list);
  const isTobaccosListLoading = useSelector(tobaccosSelectors.isLoading);

  /**
   * Effects
   */
  useEffect(() => {
    const fetchTobaccoCostData = async () => {
      try {
        const response = await axiosApiInstance.get(`tobaccoCosts/classified`, { params: { tobaccos } });

        const list = response.data.data;

        setList(list);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching tobacco costPerUnit data:', error);
      }
    };
    if (!isSynchronized) return;
    fetchTobaccoCostData();
  }, [tobaccos, isSynchronized]);

  useEffect(() => {
    dispatch(GetTobaccos());
  }, [dispatch]);

  /**
   * Result
   */
  return {
    list,
    tobaccosList,
    isTobaccosListLoading,
  };
};
