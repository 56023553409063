import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// lodash
import { isString } from 'lodash';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, Stack } from '@mui/material';
// hooks
import { useTranslation } from 'react-i18next';
import useResponsive from '../../hooks/useResponsive';
import { useAuth } from '../../providers/AuthProvider';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import NavSectionV2 from '../../components/NavSectionV2';
// nav config
import navConfigV2 from './NavConfigV2';
import navConfig from './NavConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { account } = useAuth();
  const { userPagePermissions } = account || {};
  const isDesktop = useResponsive('up', 'lg');

  // console.log('userPagePermissions', userPagePermissions);
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Link underline="none" component={RouterLink} to="/dashboard/app">
        <Stack px={2.5} py={2} direction={'row'} alignItems={'center'} spacing={2}>
          <Logo disabledLink />

          {isString(account.factory?.name) && (
            <Typography variant="subtitle2" color={'primary.main'}>
              {account.factory.name}
            </Typography>
          )}
        </Stack>
      </Link>

      <Box sx={{ mx: 1 }}>
        <AccountStyle>
          <Avatar src={account.photoURL} alt="photoURL" />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {account.fullName}
            </Typography>
            {account.role && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t(`roles.${account.role}`)}
              </Typography>
            )}
          </Box>
        </AccountStyle>
      </Box>

      {/* {console.log('navConfig', navConfig)} */}

      {userPagePermissions && userPagePermissions.length > 0 ? (
        <NavSectionV2 navConfig={navConfigV2} />
      ) : (
        <NavSection navConfig={navConfig} />
      )}

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
