import PropTypes from 'prop-types';
import { useRef } from 'react';
// mui
import { Tooltip, IconButton } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// csv
import { CSVLink } from 'react-csv';
// components
import Iconify from './Iconify';

// --------------------------------------------------------------------

const DownloadCsvIconButton = ({ data, canDownloadIfEmptyData, headers, filename, text, color, icon, ...rest }) => {
  const { t } = useTranslation();
  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const onClick = () => csvLink.current.link.click();

  return (
    <>
      <Tooltip title={text || t('button.downloadCSV')}>
        <span>
          <IconButton
            onClick={onClick}
            color={color || 'primary'}
            disabled={!canDownloadIfEmptyData && (!data || data?.length === 0)}
          >
            <Iconify icon={icon || 'bi:filetype-csv'} width={25} height={25} />
          </IconButton>
        </span>
      </Tooltip>
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        className="hidden"
        ref={csvLink}
        target="_blank"
        {...rest}
      />
    </>
  );
};

DownloadCsvIconButton.propTypes = {
  data: PropTypes.array,
  canDownloadIfEmptyData: PropTypes.bool,
  headers: PropTypes.array,
  filename: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  sx: PropTypes.object,
};

export default DownloadCsvIconButton;
