import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetConveyorStepRecords,
  GetConveyorStepRecordsByTemplate,
  GetConveyorTemplates,
  GetConveyorSimplifiedTemplates,
} from './thunks';
// reducers
import {
  GetConveyorStepRecordsReducer,
  GetConveyorStepRecordsByTemplateReducer,
  GetConveyorTemplatesReducer,
  ClearConveyorStepRecordsStateReducer,
  GetConveyorSimplifiedTemplatesReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'conveyorStepRecords',
  initialState,
  reducers: { ClearConveyorStepRecordsState: ClearConveyorStepRecordsStateReducer },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetConveyorStepRecords, GetConveyorStepRecordsReducer);
    addExtraReducer(builder, GetConveyorStepRecordsByTemplate, GetConveyorStepRecordsByTemplateReducer);
    addExtraReducer(builder, GetConveyorTemplates, GetConveyorTemplatesReducer);
    addExtraReducer(builder, GetConveyorSimplifiedTemplates, GetConveyorSimplifiedTemplatesReducer);
  },
});

export default slice.reducer;
