// redux store
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import AlertReducer from './reducers/AlertReducer';
import SettingsReducer from './reducers/SettingsReducer';

import UserNotificationsReducer from './reducers/userNotifications';

import SessionReducer from './reducers/SessionReducer';

import DashboardReducer from './reducers/dashboard';

import FactoryReducer from './reducers/FactoryReducer';

import AuthenticationActivityReducer from './reducers/authenticationActivity';

import UsersReducer from './reducers/users';
import EmployeesReducer from './reducers/EmployeesReducer';
import EmployeeTeamsReducer from './reducers/employeeTeams';
import AttendanceReducer from './reducers/AttendanceReducer';
import CigarPerformanceReducer from './reducers/cigarPerformance';
import CigarRollPricesReducer from './reducers/cigarRollPrices';
import CigarUnitProductionReducer from './reducers/cigarUnitProduction';
import TobaccoProductionReducer from './reducers/tobaccoProduction';
import TobaccoHumidityRoomProductionReducer from './reducers/tobaccoHumidityRoomProduction';
import TobaccoTransactionsReducer from './reducers/tobaccoTransactions';
import ConveyorCigarUnitProductionsReducer from './reducers/ConveyorCigarUnitProductionsReducer';
import EmployeeUnitProductionsReducer from './reducers/EmployeeUnitProductionsReducer';
import PayrollReducer from './reducers/PayrollReducer';
import ExpensesReducer from './reducers/ExpensesReducer';

import CigarsReducer from './reducers/cigar';
import cigarRecipeForTooltipReducer from './reducers/cigarRecipeForTooltip';
import CigarUnitReducer from './reducers/CigarUnitReducer';
import CigarUnitBoxReducer from './reducers/cigarUnitBoxProduction';

import WorkInProgressInventoryReducer from './reducers/workInProgressInventory';
import FinishedGoodInventoryReducer from './reducers/finishedGoodInventory';
import RawMaterialInventoryReducer from './reducers/rawMaterialInventory';
import PackingListReducer from './reducers/packingList';
import DepartmentRequestReducer from './reducers/departmentRequest';
import WorkOrdersReducer from './reducers/workOrders';
import SuppliersReducer from './reducers/suppliers';
import PurchaseOrderReducer from './reducers/purchaseOrder';

import QrCodesReducer from './reducers/QrCodesReducer';
import RoomsReducer from './reducers/rooms';

import DistributorsReducer from './reducers/distributors';

import FactoryOptionsReducer from './reducers/factoryOptions';

import qrPdfTemplatesReducer from './reducers/qrPdfTemplates';

import TobaccosReducer from './reducers/tobaccos';
import TobaccoInventoryReducer from './reducers/tobaccoInventory';

import ConveyorStepRecordsReducer from './reducers/conveyorStepRecords';

import ConveyorTemplateReducer from './reducers/conveyorTemplate';

import TobaccoSalesReducer from './reducers/tobaccoSales';

import CycleCounts from './reducers/cycleCount';

import FinishedGoodTemplate from './reducers/finishedGoodTemplate';

import boxPressedProcessReducer from './reducers/boxPressedProcess';

import tobaccoCostReducer from './reducers/tobaccoCost';

import userWhiteListReducer from './reducers/userWhiteList';
import tobaccoMappingsReducer from './reducers/tobaccoMappings';

// Middlewares
// import persistMiddleware, { reHydrateStore } from './middlewares/persistMiddleware';

// ------------------------------------------------------

export const store = configureStore({
  reducer: {
    alert: AlertReducer,
    settings: SettingsReducer,

    userNotifications: UserNotificationsReducer,

    session: SessionReducer,

    dashboard: DashboardReducer,

    factory: FactoryReducer,
    authenticationActivity: AuthenticationActivityReducer,
    users: UsersReducer,
    employees: EmployeesReducer,
    employeeTeams: EmployeeTeamsReducer,
    attendance: AttendanceReducer,
    cigarUnitProduction: CigarUnitProductionReducer,
    cigarPerformance: CigarPerformanceReducer,
    tobaccoProduction: TobaccoProductionReducer,
    tobaccoHumidityRoomProduction: TobaccoHumidityRoomProductionReducer,
    tobaccoTransactions: TobaccoTransactionsReducer,
    conveyorCigarUnitProductions: ConveyorCigarUnitProductionsReducer,
    employeeUnitProductions: EmployeeUnitProductionsReducer,
    payroll: PayrollReducer,
    expenses: ExpensesReducer,
    cigars: CigarsReducer,
    cigarRecipeForTooltip: cigarRecipeForTooltipReducer,
    cigarRollPrices: CigarRollPricesReducer,
    cigarUnit: CigarUnitReducer,
    cigarUnitBox: CigarUnitBoxReducer,

    inventory: WorkInProgressInventoryReducer,
    finishedGoodInventory: FinishedGoodInventoryReducer,
    rawMaterialInventory: RawMaterialInventoryReducer,
    packingList: PackingListReducer,
    departmentRequest: DepartmentRequestReducer,
    workOrders: WorkOrdersReducer,
    suppliers: SuppliersReducer,
    purchaseOrder: PurchaseOrderReducer,

    qrCodes: QrCodesReducer,
    rooms: RoomsReducer,

    distributors: DistributorsReducer,

    factoryOptions: FactoryOptionsReducer,
    qrPdfTemplates: qrPdfTemplatesReducer,

    tobaccos: TobaccosReducer,
    tobaccoInventory: TobaccoInventoryReducer,

    conveyorStepRecords: ConveyorStepRecordsReducer,
    conveyorTemplates: ConveyorTemplateReducer,
    tobaccoSales: TobaccoSalesReducer,
    cycleCounts: CycleCounts,
    finishedGoodTemplate: FinishedGoodTemplate,
    boxPressedProcess: boxPressedProcessReducer,
    tobaccoCost: tobaccoCostReducer,
    userWhiteList: userWhiteListReducer,
    tobaccoMappings: tobaccoMappingsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  // preloadedState: reHydrateStore(),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  // 	immutableCheck: { ignoredPaths: ['formPages'] },
  // 	serializableCheck: { ignoredPaths: ['formPages'] }
  // }).concat(persistMiddleware)
});
