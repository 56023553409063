import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// scroll bar
import 'simplebar/src/simplebar.css';
// redux
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { store } from './redux/store';
// auth
import AuthProvider from './providers/AuthProvider';
// socket
import SocketProvider from './providers/SocketProvider';
// factory options
import FactoryOptionsProvider from './providers/FactoryOptionsProvider';
// i18n
import './locales/i18n';
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <AuthProvider>
          <SocketProvider>
            <FactoryOptionsProvider>
              <App />
              <ToastContainer />
            </FactoryOptionsProvider>
          </SocketProvider>
        </AuthProvider>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
