import { useState, useEffect, useCallback } from 'react';
// material
import { Stack, Button, Typography, Box, CircularProgress } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// redux
import { useDispatch, useSelector } from 'react-redux';
import {
  GetUsers,
  CreateUserByAdmin,
  UpdateUser,
  GetPagePermissionsTemplates,
  UpdateUserPagePermissions,
  ChangeUserInactiveStatus,
} from '../../redux/reducers/users/thunks';
import { ClearPagePermissionsTemplates } from '../../redux/reducers/users/actions';
import selectors from '../../redux/reducers/users/selectors';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import StyledModal from '../../components/StyledModal';
import AddUserForm from '../../sections/@dashboard/users/admin/AddUserForm';
import EditUserForm from '../../sections/@dashboard/users/admin/EditUserForm';
import UsersTable from '../../sections/@dashboard/users/UsersTable';
import EditUserPermissionsForm from '../../sections/@dashboard/users/factory/EditUserPermissionsForm';
// hooks
import useDisclosure from '../../hooks/useDisclosure';
import { AddUserToWhiteList } from '../../redux/reducers/userWhiteList/thunks';

// ----------------------------------------------------------------------

export default function UsersWithoutFactory() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const list = useSelector(selectors.users);
  const isLoading = useSelector(selectors.isLoading);

  const pagePermissionsTemplates = useSelector(selectors.pagePermissionsTemplates.list);
  const pagePermissionsTemplatesIsLoading = useSelector(selectors.pagePermissionsTemplates.isLoading);

  // modal
  const [modalState, setModalState] = useState({ open: false, type: null, data: null, id: null });
  const onOpenModal = ({ data, type }) =>
    setModalState({
      open: true,
      type,
      ...(type === 'edit' && { data, id: data._id }),
    });
  const onCloseModal = () => setModalState({ open: false, type: null, data: null, id: null });

  useEffect(() => {
    dispatch(GetUsers({ withoutFactory: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreate = (data) => {
    dispatch(CreateUserByAdmin(data)).then(({ payload }) => {
      if (payload?.status === 'success') onCloseModal();
    });
  };

  const onUpdate = (data) => {
    dispatch(UpdateUser(data)).then(({ payload }) => {
      if (payload?.status === 'success') onCloseModal();
    });
  };

  // page permissions
  const onGetPagePermissionsTemplates = () => {
    dispatch(GetPagePermissionsTemplates());
  };

  const onClearPagePermissionsTemplates = () => {
    dispatch(ClearPagePermissionsTemplates());
  };

  const pagePermissionsModalDisclosure = useDisclosure();
  const [pagePermissionsModalUser, setPagePermissionsModalUser] = useState(null);

  const onOpenPagePermissionsModal = (user) => {
    setPagePermissionsModalUser(user);
    onGetPagePermissionsTemplates();

    pagePermissionsModalDisclosure.open();
  };

  const onClosePagePermissionsModal = () => {
    pagePermissionsModalDisclosure.close();

    setPagePermissionsModalUser(null);
    onClearPagePermissionsTemplates();
  };

  const onUpdatePagePermissions = ({ userId, data }) => {
    dispatch(UpdateUserPagePermissions({ userId, data })).then(({ payload }) => {
      if (payload?.status === 'success') onClosePagePermissionsModal();
    });
  };

  const onAddUserToWhiteList = useCallback(
    (id) => {
      dispatch(AddUserToWhiteList(id));
    },
    [dispatch]
  );

  const onChangeInactiveStatus = useCallback(
    (id, inactiveStatus) => {
      dispatch(ChangeUserInactiveStatus({ userId: id, inactiveStatus }));
    },
    [dispatch]
  );

  if (isLoading) {
    return (
      <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <Page title={t('pages.users')}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          {t('pages.users')}
        </Typography>

        <Box display="flex" gap="10px">
          <Button
            variant="contained"
            onClick={() => onOpenModal({ type: 'add' })}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {t('usersPage.button.new')}
          </Button>
        </Box>
      </Stack>

      <UsersTable
        list={list}
        onEdit={(data) => onOpenModal({ data, type: 'edit' })}
        onOpenPagePermissionsModal={onOpenPagePermissionsModal}
        onAddToWhiteList={onAddUserToWhiteList}
        onChangeInactiveStatus={onChangeInactiveStatus}
      />

      <StyledModal
        title={modalState.type === 'add' ? t('usersPage.button.new') : t('usersPage.button.edit')}
        open={modalState.open}
        handleClose={onCloseModal}
        maxWidth={'sm'}
        showCloseModalWithUnsavedDataConfirmation
      >
        {modalState.type === 'add' ? (
          <AddUserForm onCreate={onCreate} />
        ) : (
          <EditUserForm data={modalState.data} id={modalState.id} onUpdate={onUpdate} />
        )}
      </StyledModal>

      <StyledModal
        maxWidth={'lg'}
        title={t('button.editPermissions')}
        subtitle={pagePermissionsModalUser?.fullName || ''}
        open={Boolean(pagePermissionsModalDisclosure.isOpen)}
        handleClose={onClosePagePermissionsModal}
        isLoading={pagePermissionsTemplatesIsLoading}
        showCloseModalWithUnsavedDataConfirmation
      >
        <EditUserPermissionsForm
          pagePermissionsTemplates={pagePermissionsTemplates}
          pagePermissions={pagePermissionsModalUser?.userPagePermissions}
          onUpdate={onUpdatePagePermissions}
          id={pagePermissionsModalUser?._id}
        />
      </StyledModal>
    </Page>
  );
}
