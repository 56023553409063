import { createAsyncThunk } from '@reduxjs/toolkit';
// dayjs
import dayjs from 'dayjs';
// api
import tobaccoProductionsApi from '../../../api/tobaccoProductionsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'tobaccoProduction';

export const GetConveyorTobaccoProduction = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorTobaccoProduction`,
  async (params, thunkAPI) => {
    params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

    const res = await tobaccoProductionsApi.get(params);

    if (res.status === 200) return { ...res.data?.data, startDate: params.startDate, endDate: params.endDate };

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccoConveyorSteps = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoConveyorSteps`,
  async (__, thunkAPI) => {
    const res = await tobaccoProductionsApi.getTobaccoConveyorSteps();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
