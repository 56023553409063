import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { Box, Card, CardHeader } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// chart
import ReactApexChart from 'react-apexcharts';
// lodash
import _ from 'lodash';
// components
import { BaseOptionChart } from '../../../../components/chart';
import { ProductionChartFilters } from './components';
// hooks
import { useData, useFormFilter } from './hooks';

// ------------------------------------------------------------------

const ProductionChart = ({ title, subheader }) => {
  const { t } = useTranslation();

  const { methods, endDate, startDate, cigarsValue, isSynchronized } = useFormFilter();
  const { cigars, cigarUnitProductions, isCigarsLoading } = useData({
    isSynchronized,
    handleSubmit: methods.handleSubmit,
    endDate,
    startDate,
    selectedCigars: cigarsValue,
  });

  // chart data
  const baseOptions = BaseOptionChart();

  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    // Extract all unique dates from the List data, sort them in ascending order
    const allDates = _.chain(cigarUnitProductions)
      .flatMap((item) => _.flatMap(_.keys(item.byDates), (key) => key))
      .uniq()
      .sort()
      .value();

    // Map List To Series
    const series = _.map(cigarUnitProductions, ({ blendName, byDates }) => ({
      name: blendName,
      data: _.map(allDates, (date) => ({
        x: new Date(date),
        y: byDates[date] ? byDates[date].total : 0,
      })),
    }));

    const options = {
      plotOptions: { bar: { columnWidth: '16%' } },
      fill: { type: series.map((i) => i.fill) },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: (date) => {
            if (date) return t('date.short', { date: new Date(date) });
            return date;
          },
        },
      },
      yaxis: {
        title: {
          text: t('label.quantity'),
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
    };

    setChartData({
      series,
      options: _.merge(baseOptions, options),
    });
  }, [t, cigarUnitProductions, baseOptions]);

  return (
    <Card>
      <CardHeader title={title} subheader={subheader} />

      <ProductionChartFilters
        methods={methods}
        cigarsList={cigars}
        isLoading={isCigarsLoading}
        selectedListCount={cigarsValue.length}
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData.series} options={chartData.options} height={500} />
      </Box>
    </Card>
  );
};

ProductionChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default ProductionChart;
