import PropTypes from 'prop-types';
// mui
import { Box, Skeleton, TableCell as MuiTableCell } from '@mui/material';
import { styled } from '@mui/system';
// lodash
import _, { get, isFunction } from 'lodash';
// hooks
import useAccess from '../../../hooks/useAccess';
import { useTableContext } from '../../../providers/TableProvider';

// -------------------------------------------------------------------

const StyledTableCell = styled(MuiTableCell, {
  shouldForwardProp: (prop) => prop !== 'bold' && prop !== 'size' && prop !== 'noWrap',
})(({ theme, color, size, bold, onClick, noWrap }) => ({
  ...(isFunction(onClick) && {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  }),
  // ...((size === 'sm' || size === 'xs') && {
  padding: theme.spacing(0.2, 1),
  // }),
  ...(color && { color: get(theme.palette, color) }),
  ...(size === 'xs'
    ? theme.typography[bold ? 'subtitle4' : 'body4']
    : size === 'sm'
    ? theme.typography[bold ? 'subtitle3' : 'body3']
    : theme.typography[bold ? 'subtitle2' : 'body2']),
  ...(noWrap && { whiteSpace: 'noWrap' }),
}));

const TableCell = ({ isLoading, children, id, actionId, onClick, ...rest }) => {
  const tableContext = useTableContext();
  const { permissionPrefix, cells } = tableContext || {};

  const cell = _.find(cells, { id });
  const { noAccessByDefault } = cell || {};

  const hasViewAccess = useAccess(`${permissionPrefix}.views.${id}`);
  const hasActionAccess = useAccess(`${permissionPrefix}.actions.${actionId}`);
  if (actionId && !permissionPrefix) {
    // eslint-disable-next-line no-console
    console.error('Please provide permissionPrefix to TableProvider');
  }

  if (!(!noAccessByDefault || hasViewAccess)) {
    return null;
  }

  if (isLoading) {
    return (
      <StyledTableCell {...rest}>
        <Skeleton>
          <Box>{children}</Box>
        </Skeleton>
      </StyledTableCell>
    );
  }

  return (
    <StyledTableCell {...(actionId && hasActionAccess && { onClick })} {...rest}>
      {children}
    </StyledTableCell>
  );
};

TableCell.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  noAccessByDefault: PropTypes.bool,
  id: PropTypes.string,
  actionId: PropTypes.string,
  onClick: PropTypes.func,
  permissionPrefix: PropTypes.string,
};

export const SmallTableCell = (props) => <TableCell {...props} size={'sm'} />;
export const ExtraSmallTableCell = (props) => <TableCell {...props} size={'xs'} />;

export default TableCell;
