import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetCycleCountById,
  GetCycleCountResult,
  GetCycleCounts,
  GetPendingCycleCountRequests,
  UpdateCycleCount,
  UpdateCycleCountResult,
  ChangeCycleCountStatus,
} from './thunks';
// reducers
import {
  GetCycleCountsReducer,
  ClearSelectedCycleCountReducer,
  GetCycleCountByIdReducer,
  UpdateCycleCountReducer,
  GetCycleCountResultByIdReducer,
  GetPendingCycleCountRequestsReducer,
  UpdateCycleCountResultReducer,
  ResetCycleCountStateReducer,
  ChangeCycleCountStatusReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'cycleCounts',
  initialState,
  reducers: {
    ClearSelectedCycleCount: ClearSelectedCycleCountReducer,
    ResetCycleCountState: ResetCycleCountStateReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetCycleCounts, GetCycleCountsReducer);
    addExtraReducer(builder, GetCycleCountById, GetCycleCountByIdReducer);
    addExtraReducer(builder, UpdateCycleCount, UpdateCycleCountReducer);
    addExtraReducer(builder, ChangeCycleCountStatus, ChangeCycleCountStatusReducer);
    addExtraReducer(builder, GetCycleCountResult, GetCycleCountResultByIdReducer);
    addExtraReducer(builder, GetPendingCycleCountRequests, GetPendingCycleCountRequestsReducer);
    addExtraReducer(builder, UpdateCycleCountResult, UpdateCycleCountResultReducer);
  },
});

export default slice.reducer;
