import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  CreateInventoryItem,
  GetInventoryItem,
  GetInventory,
  GetInventoryV2,
  RemoveInventoryItem,
  UpdateInventoryItem,
  GetInventoryHistory,
  GetCigarTags,
} from './thunks';
// reducers
import {
  CreateInventoryItemReducer,
  GetInventoryItemReducer,
  GetInventoryReducer,
  GetInventoryV2Reducer,
  RemoveInventoryItemReducer,
  UpdateInventoryItemReducer,
  GetInventoryHistoryReducer,
  ResetWorkInProgressInventoryStateReducer,
  GetCigarTagsReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    ResetWorkInProgressInventoryState: ResetWorkInProgressInventoryStateReducer,
  },
  extraReducers: (builder) => {
    // GetInventoryItem
    addExtraReducer(builder, GetInventoryItem, GetInventoryItemReducer);
    // GetInventory
    addExtraReducer(builder, GetInventory, GetInventoryReducer);
    // GetInventoryV2
    addExtraReducer(builder, GetInventoryV2, GetInventoryV2Reducer);
    // GetInventoryHistory
    addExtraReducer(builder, GetInventoryHistory, GetInventoryHistoryReducer);
    // UpdateInventoryItem
    addExtraReducer(builder, UpdateInventoryItem, UpdateInventoryItemReducer);
    // CreateInventoryItem
    addExtraReducer(builder, CreateInventoryItem, CreateInventoryItemReducer);
    // RemoveInventoryItem
    addExtraReducer(builder, RemoveInventoryItem, RemoveInventoryItemReducer);

    // Get Cigar Tags
    addExtraReducer(builder, GetCigarTags, GetCigarTagsReducer);
  },
});

export default inventorySlice.reducer;
