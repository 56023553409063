import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetWorkOrders, GetWorkOrderById, CreateWorkOrder, UpdateWorkOrder } from './thunks';
// reducers
import {
  GetWorkOrdersReducer,
  ClearSelectedWorkOrderReducer,
  ResetWorkOrdersStateReducer,
  GetWorkOrderByIdReducer,
  CreateWorkOrderReducer,
  UpdateWorkOrderReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'workOrders',
  initialState,
  reducers: {
    ClearSelectedWorkOrder: ClearSelectedWorkOrderReducer,
    ResetWorkOrdersState: ResetWorkOrdersStateReducer,
  },
  extraReducers: (builder) => {
    // GetUsers
    addExtraReducer(builder, GetWorkOrders, GetWorkOrdersReducer);
    // GetById
    addExtraReducer(builder, GetWorkOrderById, GetWorkOrderByIdReducer);
    // Create
    addExtraReducer(builder, CreateWorkOrder, CreateWorkOrderReducer);
    // Update
    addExtraReducer(builder, UpdateWorkOrder, UpdateWorkOrderReducer);
    // Import
    // addExtraReducer(builder, ImportUsers, ImportUsersReducer);
  },
});

export default slice.reducer;
