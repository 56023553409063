import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetCigars,
  CreateCigar,
  UpdateCigar,
  ToggleCigarInactive,
  GetCigarOptions,
  GetCigarRecipeById,
  GetCigarRecipesHistory,
  GetTobaccos,
  GetCigarsWithHistory,
  GetTobaccoMappingTurnOn,
} from './thunks';
// reducers
import {
  GetCigarsReducer,
  GetCigarsWithHistoryReducer,
  CreateCigarReducer,
  UpdateCigarReducer,
  ToggleCigarInactiveReducer,
  GetCigarOptionsReducer,
  ClearSelectedCigarRecipeReducer,
  GetCigarRecipeByIdReducer,
  GetCigarRecipesHistoryReducer,
  GetTobaccosReducer,
  ClearSelectedCigarRecipesHistoryReducer,
  ResetCigarsStateReducer,
  GetTobaccoMappingTurnOnReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'cigars',
  initialState,
  reducers: {
    ClearSelectedCigarRecipe: ClearSelectedCigarRecipeReducer,
    ClearSelectedCigarRecipesHistory: ClearSelectedCigarRecipesHistoryReducer,
    ResetCigarsState: ResetCigarsStateReducer,
  },
  extraReducers: (builder) => {
    // Cigar
    addExtraReducer(builder, GetCigars, GetCigarsReducer);
    addExtraReducer(builder, GetCigarsWithHistory, GetCigarsWithHistoryReducer);
    addExtraReducer(builder, CreateCigar, CreateCigarReducer);
    addExtraReducer(builder, UpdateCigar, UpdateCigarReducer);
    addExtraReducer(builder, ToggleCigarInactive, ToggleCigarInactiveReducer);
    addExtraReducer(builder, GetCigarOptions, GetCigarOptionsReducer);

    // Tobaccos
    addExtraReducer(builder, GetTobaccos, GetTobaccosReducer);

    // Recipe
    addExtraReducer(builder, GetCigarRecipeById, GetCigarRecipeByIdReducer);

    // Recipes History
    addExtraReducer(builder, GetCigarRecipesHistory, GetCigarRecipesHistoryReducer);

    // Tobacco mapping on state
    addExtraReducer(builder, GetTobaccoMappingTurnOn, GetTobaccoMappingTurnOnReducer);
  },
});

export default slice.reducer;
