import PropTypes from 'prop-types';

// @mui
import {
  ListItemText,
  TextField,
  Checkbox,
  Button,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Divider,
  Box,
} from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import StyledModal from '../StyledModal';
import Scrollbar from '../Scrollbar';

const ExpandBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 0,
  paddingBottom: 8,
});

const ExpandScrollbar = styled(Scrollbar)({
  maxHeight: '40vh',
});

const ExpandListItem = styled(ListItem)({
  padding: 0,
});

const ExpandDialogActions = styled(DialogActions)({
  paddingTop: 16,
  gap: 8,
});

const ExpandButton = styled(Button)({
  height: '56px',
  minWidth: '140px',
});

const ExpandAutocomplete = ({
  open,
  handleClose,
  handleSearchChange,
  handleSelectAll,
  handleSelectOption,
  handleSave,
  searchTerm,
  selectAll,
  options,
  field,
  onGetOptionLabel,
  selectedOptions,
}) => {
  const { t } = useTranslation();

  return (
    <StyledModal
      maxWidth={'md'}
      title={t('expandAutocomplete.searchAndSelect')}
      open={open}
      handleClose={handleClose}
      withoutScroll
    >
      <DialogContent>
        <ExpandBox>
          <TextField value={searchTerm} onChange={handleSearchChange} placeholder="Search" fullWidth />

          <ExpandButton size="large" variant="outlined" onClick={handleSelectAll}>
            {selectAll ? t('button.unselectAll') : t('button.selectAll')}
          </ExpandButton>
        </ExpandBox>
        <ExpandScrollbar>
          <List>
            {options
              .filter((option) => {
                const label = onGetOptionLabel(option);
                return label.toLowerCase().includes(searchTerm.toLowerCase());
              })
              .map((option) => (
                <ExpandListItem key={`expand-list-item-${option}`}>
                  <Checkbox checked={selectedOptions.includes(option)} onChange={() => handleSelectOption(option)} />
                  <ListItemText primary={onGetOptionLabel(option)} />
                </ExpandListItem>
              ))}
          </List>
        </ExpandScrollbar>
      </DialogContent>
      <Divider />
      <ExpandDialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          {t('expandAutocomplete.cancel')}
        </Button>
        <Button size="large" variant="contained" onClick={() => handleSave(field)}>
          {t('expandAutocomplete.select')}
        </Button>
      </ExpandDialogActions>
    </StyledModal>
  );
};

ExpandAutocomplete.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleSelectOption: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  selectAll: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  field: PropTypes.object.isRequired,
  onGetOptionLabel: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ExpandAutocomplete;
