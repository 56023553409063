import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const tobaccosApi = {
  // POST
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/tobaccos`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async ({ isShowInactive }) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccos`, { params: { inactive: isShowInactive } });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccos/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/tobaccos/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateInactiveStatus: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/tobaccos/v1/toggle-inactive/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  import: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/tobaccos/import`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getTobaccoCosts: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoCosts/v1/for-tobacco/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getSimilarTobaccosCosts: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoCosts/v1/for-similar-tobaccos/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getLastCost: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoCosts/v1/last-cost/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default tobaccosApi;
