import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetUsers,
  CreateUser,
  CreateUserByAdmin,
  UpdateUser,
  GetEmployees,
  GetConveyorSteps,
  GetFeaturePermissions,
  GetPagePermissionsTemplates,
  UpdateUserPagePermissions,
  ChangePassword,
  ChangeUserInactiveStatus,
} from './thunks';
// reducers
import {
  GetUsersReducer,
  CreateUserReducer,
  CreateUserByAdminReducer,
  UpdateUserReducer,
  GetEmployeesReducer,
  GetConveyorStepsReducer,
  GetFeaturePermissionsReducer,
  GetPagePermissionsTemplatesReducer,
  ClearPagePermissionsTemplatesReducer,
  changePasswordReducer,
  ResetUsersStateReducer,
  ChangeUserInactiveStatusReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    ClearPagePermissionsTemplates: ClearPagePermissionsTemplatesReducer,
    ResetUsersState: ResetUsersStateReducer,
  },
  extraReducers: (builder) => {
    // GetUsers
    addExtraReducer(builder, GetUsers, GetUsersReducer);
    // CreateUser
    addExtraReducer(builder, CreateUser, CreateUserReducer);
    // CreateUserByAdmin
    addExtraReducer(builder, CreateUserByAdmin, CreateUserByAdminReducer);
    // UpdateUser
    addExtraReducer(builder, UpdateUser, UpdateUserReducer);
    // GetEmployees
    addExtraReducer(builder, GetEmployees, GetEmployeesReducer);
    // GetEmployees
    addExtraReducer(builder, GetConveyorSteps, GetConveyorStepsReducer);
    // GetFeaturePermissions
    addExtraReducer(builder, GetFeaturePermissions, GetFeaturePermissionsReducer);
    // GetPagePermissionsTemplates
    addExtraReducer(builder, GetPagePermissionsTemplates, GetPagePermissionsTemplatesReducer);
    // UpdateUserPagePermissions
    addExtraReducer(builder, UpdateUserPagePermissions, UpdateUserReducer);
    // ChangePassword
    addExtraReducer(builder, ChangePassword, changePasswordReducer);
    // Change Inactive User Status
    addExtraReducer(builder, ChangeUserInactiveStatus, ChangeUserInactiveStatusReducer);
  },
});

export default slice.reducer;
