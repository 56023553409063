import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// reducers
import { ClearCigarRecipeForTooltipReducer, GetCigarRecipeForTooltipReducer } from './reducers';
// thunks
import { GetCigarRecipeForTooltip } from './thunks';

export const slice = createSlice({
  name: 'cigarRecipeForTooltip',
  initialState,
  reducers: {
    ClearCigarRecipeForTooltip: ClearCigarRecipeForTooltipReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetCigarRecipeForTooltip, GetCigarRecipeForTooltipReducer);
  },
});

export default slice.reducer;
