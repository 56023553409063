import PropTypes from 'prop-types';
// i18n
import { useTranslation } from 'react-i18next';
// mui
import { Stack, Typography } from '@mui/material';
// components
import { CigarUnitHistoryOperation } from './CigarUnitHistoryOperation';

// ---------------------------------------------------------------------------

const CigarUnitHistory = ({ history, cigars, employees, supervisors, statuses, rooms }) => {
  const { t } = useTranslation();

  if (history?.length === 0) return null;

  return (
    <Stack spacing={3}>
      <Typography variant="h4" gutterBottom>
        {t('label.editHistory')}
      </Typography>
      {history.map((operation, i) => (
        <CigarUnitHistoryOperation
          key={operation._id}
          cigarUnitHistory={operation}
          cigars={cigars}
          employees={employees}
          supervisors={supervisors}
          statuses={statuses}
          rooms={rooms}
          index={history.length - (i + 1)}
          prevOperationCreatedAt={history[i + 1]?.createdAt}
        />
      ))}
    </Stack>
  );
};

CigarUnitHistory.propTypes = {
  history: PropTypes.array,
  cigars: PropTypes.array,
  employees: PropTypes.array,
  supervisors: PropTypes.array,
  statuses: PropTypes.array,
  rooms: PropTypes.array,
};

export default CigarUnitHistory;
