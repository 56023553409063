import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetTobaccosInStock, GetTotalStats } from './thunks';
// reducers
import { GetTobaccosInStockReducer, GetTotalStatsReducer, ResetDashboardStateReducer } from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    ResetDashboardState: ResetDashboardStateReducer,
  },
  extraReducers: (builder) => {
    // GetTobaccosInStock
    addExtraReducer(builder, GetTobaccosInStock, GetTobaccosInStockReducer);
    // GetTotalStats
    addExtraReducer(builder, GetTotalStats, GetTotalStatsReducer);
  },
});

export default slice.reducer;
