import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetTobaccoSales,
  DeleteTobaccoSaleContainer,
  UpdateTobaccoSale,
  CreateFactoryOption,
  GetTobaccoSaleOptions,
} from './thunks';
// reducers
import {
  GetTobaccoSalesReducer,
  DeleteTobaccoSaleContainerReducer,
  UpdateTobaccoSaleReducer,
  CreateFactoryOptionReducer,
  GetTobaccoSaleOptionsReducer,
  ResetTobaccoSalesStateReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoSales',
  initialState,
  reducers: {
    ResetTobaccoSalesState: ResetTobaccoSalesStateReducer,
  },
  extraReducers: (builder) => {
    // Get
    addExtraReducer(builder, GetTobaccoSales, GetTobaccoSalesReducer);
    // Delete
    addExtraReducer(builder, DeleteTobaccoSaleContainer, DeleteTobaccoSaleContainerReducer);
    // Update
    addExtraReducer(builder, UpdateTobaccoSale, UpdateTobaccoSaleReducer);
    // GetTobaccoSaleOptions
    addExtraReducer(builder, GetTobaccoSaleOptions, GetTobaccoSaleOptionsReducer);
    // CreateFactoryOption
    addExtraReducer(builder, CreateFactoryOption, CreateFactoryOptionReducer);
  },
});

export default slice.reducer;
