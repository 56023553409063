import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import userNotificationsApi from '../../../api/userNotificationsApi';
// enums
import { NotificationTypeEnum } from '../../../constants/enums';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'userNotifications';

export const GetNotifications = createAsyncThunk(`${REDUCER_NAME}/getNotifications`, async (__, thunkAPI) => {
  const res = await userNotificationsApi.get({ type: NotificationTypeEnum.WebPush });

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetLastNotifications = createAsyncThunk(`${REDUCER_NAME}/getLastNotifications`, async (__, thunkAPI) => {
  const res = await userNotificationsApi.get({ type: NotificationTypeEnum.WebPush, limit: 10 });

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetNotificationsTotalUnread = createAsyncThunk(`${REDUCER_NAME}/getTotalUnread`, async (__, thunkAPI) => {
  const res = await userNotificationsApi.getTotalUnread();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const MarkNotificationAsRead = createAsyncThunk(
  `${REDUCER_NAME}/markNotificationAsRead`,
  async (id, thunkAPI) => {
    const res = await userNotificationsApi.markAsRead(id);

    if (res.status === 200) return id;

    return thunkAPI.rejectWithValue();
  }
);

export const MarkNotificationAsUnRead = createAsyncThunk(
  `${REDUCER_NAME}/markNotificationAsUnRead`,
  async (id, thunkAPI) => {
    const res = await userNotificationsApi.markAsUnRead(id);

    if (res.status === 200) return id;

    return thunkAPI.rejectWithValue();
  }
);

export const MarkAllNotificationsAsRead = createAsyncThunk(
  `${REDUCER_NAME}/markAllNotificationsAsRead`,
  async (__, thunkAPI) => {
    const res = await userNotificationsApi.markAllAsRead();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
