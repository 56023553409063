import PropTypes from 'prop-types';
// material
import { Paper, Skeleton, Stack, Typography } from '@mui/material';
// i18n
import { Trans, useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default function SearchNotFound({ searchQuery = '', isLoading, ...other }) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Paper {...other}>
        <Stack alignItems={'center'} justifyContent={'center'}>
          <Skeleton>
            <Typography gutterBottom align="center" variant="subtitle1">
              {t('search.notFound')}
            </Typography>
          </Skeleton>
          <Skeleton>
            <Typography variant="body2" align="center">
              <Trans i18nKey="search.notFoundText" values={{ value: searchQuery }} components={{ bold: <b /> }} />
            </Typography>
          </Skeleton>
        </Stack>
      </Paper>
    );
  }

  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {t('search.notFound')}
      </Typography>
      <Typography variant="body2" align="center">
        <Trans i18nKey="search.notFoundText" values={{ value: searchQuery }} components={{ bold: <b /> }} />
      </Typography>
    </Paper>
  );
}
