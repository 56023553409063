import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  DeleteFactoryOption,
  GetFactoryOptionById,
  GetFactoryOptions,
  ImportFactoryOptions,
  UpdateFactoryOption,
  UpdateInactive,
} from './thunks';
// reducers
import {
  GetFactoryOptionsReducer,
  ImportFactoryOptionsReducer,
  ClearSelectedFactoryOptionsUserReducer,
  GetFactoryOptionByIdReducer,
  UpdateFactoryOptionReducer,
  UpdateInactiveReducer,
  DeleteFactoryOptionReducer,
  ResetFactoryOptionsStateReducers,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'factoryOptions',
  initialState,
  reducers: {
    ClearSelectedFactoryOptionUser: ClearSelectedFactoryOptionsUserReducer,
    ResetFactoryOptionsState: ResetFactoryOptionsStateReducers,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetFactoryOptions, GetFactoryOptionsReducer);
    addExtraReducer(builder, ImportFactoryOptions, ImportFactoryOptionsReducer);
    addExtraReducer(builder, GetFactoryOptionById, GetFactoryOptionByIdReducer);
    addExtraReducer(builder, UpdateFactoryOption, UpdateFactoryOptionReducer);
    addExtraReducer(builder, UpdateInactive, UpdateInactiveReducer);
    addExtraReducer(builder, DeleteFactoryOption, DeleteFactoryOptionReducer);
  },
});

export default slice.reducer;
