// import initialState from './initialState';
// utils
import _ from 'lodash';

// --------------------------------------------------------------------

export const GetNotificationsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: data.list || [],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetLastNotificationsReducer = {
  pending: (state) => ({
    ...state,
    lastNotifications: {
      ...state.lastNotifications,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      lastNotifications: {
        list: data.list || [],
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    lastNotifications: {
      ...state.lastNotifications,
      loading: 'failed',
    },
  }),
};

export const GetNotificationsTotalUnreadReducer = {
  pending: (state) => ({
    ...state,
    totalUnread: {
      ...state.totalUnread,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      totalUnread: {
        data: data || 0,
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    totalUnread: {
      ...state.totalUnread,
      loading: 'failed',
    },
  }),
};

export const MarkNotificationAsReadReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
    lastNotifications: {
      ...state.lastNotifications,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const id = action.payload;

    return {
      ...state,
      list: _.map(state.list, (notification) => {
        if (notification._id === id) {
          return {
            ...notification,
            isRead: true,
          };
        }

        return notification;
      }),
      loading: 'succeeded',
      lastNotifications: {
        list: _.map(state.lastNotifications.list, (notification) => {
          if (notification._id === id) {
            return {
              ...notification,
              isRead: true,
            };
          }

          return notification;
        }),
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
    lastNotifications: {
      ...state.lastNotifications,
      loading: 'failed',
    },
  }),
};

export const MarkNotificationAsUnreadReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
    lastNotifications: {
      ...state.lastNotifications,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const id = action.payload;

    return {
      ...state,
      loading: 'succeeded',
      list: _.map(state.list, (notification) => {
        if (notification._id === id) {
          return {
            ...notification,
            isRead: false,
          };
        }

        return notification;
      }),
      lastNotifications: {
        list: _.map(state.lastNotifications.list, (notification) => {
          if (notification._id === id) {
            return {
              ...notification,
              isRead: false,
            };
          }

          return notification;
        }),
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
    lastNotifications: {
      ...state.lastNotifications,
      loading: 'failed',
    },
  }),
};

export const MarkAllNotificationsAsReadReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
    lastNotifications: {
      ...state.lastNotifications,
      loading: 'pending',
    },
  }),
  fulfilled: (state) => ({
    ...state,
    loading: 'succeeded',
    list: _.map(state.list, (notification) => ({
      ...notification,
      isRead: true,
    })),
    lastNotifications: {
      list: _.map(state.lastNotifications.list, (notification) => ({
        ...notification,
        isRead: true,
      })),
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
    lastNotifications: {
      ...state.lastNotifications,
      loading: 'failed',
    },
  }),
};

// export const ClearPagePermissionsTemplatesReducer = (state) => ({
//   ...state,
//   pagePermissionsTemplates: initialState.pagePermissionsTemplates,
// });
