// lodash
import { get } from 'lodash';
// useAccess
import useAccess from './useAccess';

// -----------------------------------------------------------------------

const useAccessFromAccessObject = (object, path) => {
  const roles = get(object, path);

  const hasAccess = useAccess(null, roles);

  return hasAccess;
};

export default useAccessFromAccessObject;
