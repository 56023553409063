import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { useState } from 'react';
// material
import { Card, Table, Checkbox, TableRow, TableBody, TableContainer, Stack, Box, Divider } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// date fns
import { parseISO } from 'date-fns';
// components
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import { TableHead, TableToolbar, TableMoreMenu, TableCell } from '../table';
// utils
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
import { getEmployeeLabel } from '../../../utils/modelLabels';
import getLocalizedText from '../../../utils/getLocalizedText';
import TableMoreMenuItem from '../table/TableMoreMenu/TableMoreMenuItem';
// enums
import { UserRoleEnum } from '../../../services/access';
import useAccess from '../../../hooks/useAccess';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'fullName', label: t('table.name'), alignRight: false },
  { id: 'employee', label: t('usersPage.table.employee'), alignRight: false, disableSort: true },
  { id: 'email', label: t('usersPage.table.email'), alignRight: false },
  { id: 'role', label: t('usersPage.table.role'), alignRight: false },
  { id: 'featurePermissions', label: t('usersPage.table.featurePermissions'), alignRight: false, disableSort: true },
  { id: 'conveyorPermissions', label: t('usersPage.table.conveyorPermissions'), alignRight: false, disableSort: true },
  { id: 'active', label: t('table.active'), alignRight: false },
  { id: 'createdAt', label: t('table.created'), alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

const getFilteredItem = (item, t) => ({
  fullName: item.fullName,
  employee: getEmployeeLabel(item.employee),
  email: item.email,
  ...(item.role && { role: t(`roles.${item.role}`) }),
  ...item.featurePermissions?.reduce((acc, { _id, name }) => ({ ...acc, [`feature_${_id}`]: name }), {}),
  ...item.conveyorPermissions?.reduce((acc, { _id, name }) => ({ ...acc, [`conveyor_${_id}`]: name }), {}),
  createdAt: t('date.shortWithTime', { date: parseISO(item.createdAt) }),
});

export default function UsersTable({
  list,
  onEdit,
  onOpenPagePermissionsModal,
  sx,
  onOpenChangePasswordModal,
  onAddToWhiteList,
  onChangeInactiveStatus,
}) {
  const { t, i18n } = useTranslation();

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const tableHead = getTableHead(t);

  const filteredItems = applySortFilter(list, getComparator(order, orderBy), filterName, (item) =>
    getFilteredItem(item, t)
  );

  const isItemNotFound = filteredItems.length === 0;

  const canEditUser = useAccess('users.actions.edit');
  const canEditPermissions = useAccess('users.actions.editPermissions');

  return (
    <Card sx={sx}>
      <TableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredItems.map((row) => {
                const {
                  _id,
                  fullName,
                  employee,
                  email,
                  role,
                  featurePermissions,
                  conveyorPermissions,
                  createdAt,
                  inactive,
                } = row;
                const isItemSelected = selected.indexOf(_id) !== -1;

                return (
                  <TableRow
                    hover
                    key={_id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    sx={{ ...(inactive && { backgroundColor: (t) => t.palette.greyTheme.lighter }) }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                    </TableCell>
                    <TableCell
                      align="left"
                      {...(canEditUser && { onClick: () => onEdit(row) })}
                      sx={{ width: { xs: '50px', sm: '150px', md: 'auto' } }}
                    >
                      {fullName}
                    </TableCell>
                    <TableCell align="left">{getEmployeeLabel(employee)}</TableCell>
                    <TableCell align="left">{email}</TableCell>
                    <TableCell align="left">{role && t(`roles.${role}`)}</TableCell>
                    <TableCell align="left" noWrap>
                      <Stack divider={<Divider />}>
                        {sortBy(featurePermissions || [], 'name').map(({ _id, name }) => (
                          <Box key={_id}>{name}</Box>
                        ))}
                      </Stack>
                    </TableCell>
                    <TableCell align="left" noWrap>
                      <Stack divider={<Divider />}>
                        {sortBy(conveyorPermissions || [], 'name').map(({ _id, icon, name, nameEs }) => {
                          const translatedName = getLocalizedText({ en: name, es: nameEs, language: i18n.language });

                          return (
                            <Stack direction={'row'} spacing={1} alignItems={'center'} key={_id}>
                              <Iconify icon={`mdi:${icon}`} />
                              <Box>{translatedName}</Box>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </TableCell>
                    <TableCell>{inactive ? t('label.no') : t('label.yes')}</TableCell>
                    <TableCell align="left" noWrap>
                      {t('date.shortWithTime', { date: parseISO(createdAt) })}
                    </TableCell>

                    <TableCell align="right">
                      <TableMoreMenu>
                        {canEditUser && (
                          <TableMoreMenuItem onClick={() => onEdit(row)} icon={'bi:pencil-square'}>
                            {t('button.edit')}
                          </TableMoreMenuItem>
                        )}

                        {canEditPermissions && onOpenPagePermissionsModal && role !== UserRoleEnum.Admin && (
                          <TableMoreMenuItem onClick={() => onOpenPagePermissionsModal(row)} icon={'bi:key'}>
                            {t('button.editPermissions')}
                          </TableMoreMenuItem>
                        )}
                        <TableMoreMenuItem onClick={() => onOpenChangePasswordModal(_id)} icon={'bi:key'}>
                          {t('usersPage.button.changePassword')}
                        </TableMoreMenuItem>
                        <TableMoreMenuItem onClick={() => onAddToWhiteList(_id)} icon={'mdi:shield-check'}>
                          {t('usersPage.button.addToWhiteList')}
                        </TableMoreMenuItem>
                        <TableMoreMenuItem
                          onClick={() => onChangeInactiveStatus(_id, !inactive)}
                          icon={inactive ? 'fa:toggle-on' : 'fa:toggle-off'}
                        >
                          {t(`label.${inactive ? 'activate' : 'deactivate'}`)}
                        </TableMoreMenuItem>
                      </TableMoreMenu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            {isItemNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={tableHead.length} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

UsersTable.propTypes = {
  list: PropTypes.array,
  onEdit: PropTypes.func,
  onOpenPagePermissionsModal: PropTypes.func,
  sx: PropTypes.object,
  onOpenChangePasswordModal: PropTypes.func,
  onAddToWhiteList: PropTypes.func,
  onChangeInactiveStatus: PropTypes.func,
};
