import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import purchaseOrdersApi from '../../../api/purchaseOrdersApi';
import rawMaterialTemplateApi from '../../../api/rawMaterialTemplateApi';
import suppliersApi from '../../../api/suppliersApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'purchaseOrder';

export const CreatePurchaseOrder = createAsyncThunk(`${REDUCER_NAME}/createPurchaseOrder`, async (data, thunkAPI) => {
  const res = await purchaseOrdersApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetPurchaseOrderById = createAsyncThunk('payroll/getPurchaseOrderById', async (id, thunkAPI) => {
  const res = await purchaseOrdersApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetPurchaseOrders = createAsyncThunk(`${REDUCER_NAME}/getPurchaseOrders`, async (__, thunkAPI) => {
  const res = await purchaseOrdersApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdatePurchaseOrder = createAsyncThunk(
  `${REDUCER_NAME}/updatePurchaseOrder`,
  async ({ data, id }, thunkAPI) => {
    const res = await purchaseOrdersApi.update(data, id);

    if (res.status === 200) {
      return res.data;
    }

    return thunkAPI.rejectWithValue();
  }
);

export const GetPurchaseOrderRawMaterials = createAsyncThunk(
  `${REDUCER_NAME}/getPurchaseOrderRawMaterials`,
  async (__, thunkAPI) => {
    const res = await rawMaterialTemplateApi.get();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetPurchaseOrderSuppliers = createAsyncThunk(
  `${REDUCER_NAME}/getPurchaseOrderSuppliers`,
  async (__, thunkAPI) => {
    const res = await suppliersApi.get();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const CreatePurchaseOrderSupplier = createAsyncThunk(
  `${REDUCER_NAME}/createPurchaseOrderSupplier`,
  async (data, thunkAPI) => {
    const res = await suppliersApi.create(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const MarkPurchaseOrderDone = createAsyncThunk(`${REDUCER_NAME}/markPurchaseOrderDone`, async (id, thunkAPI) => {
  const res = await purchaseOrdersApi.markDone(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
