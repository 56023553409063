import PropTypes from 'prop-types';
// utils
import { get } from 'lodash';
import { Protected } from './Protected';

import { routesAccess } from '../services/access';

// ------------------------------------------------------------------

// Route
export const ProtectedRoute = ({ path, children }) => {
  const roles = get(routesAccess, path);
  // console.log('ProtectedRoute: path:', path, 'roles:', roles);
  return (
    <Protected isRoute path={path} roles={roles}>
      {children}
    </Protected>
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
};
