import PropTypes from 'prop-types';
// @mui
import { Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// lodash
import { isArray, isNumber, isString, some } from 'lodash';
// date-fns
import { parseISO } from 'date-fns';
// components
import { DataCardSection, DataCardRow } from '../../../components/DataCardSection';
// utils
import { getCigarLabel } from '../../../utils/modelLabels';

// -------------------------------------------------------------------------

const CigarData = ({ data }) => {
  const { t } = useTranslation();

  // General Data
  const { blendName, publicName, inventedAt, description } = data;

  // tier
  const tier = data.tier?.value;

  // Shape
  const { shape } = data;
  const { length, ringGauge } = shape;
  const shapeName = shape?.name?.value;

  // Leaves
  const { tobaccoLeaves } = data;
  const { filler, wrapper, binder } = tobaccoLeaves || {};

  if (!data) return null;

  return (
    <>
      <DataCardSection title={getCigarLabel({ blendName, shape })}>
        {Boolean(blendName) && (
          <DataCardRow
            title={t('qrCodeInfoPage.cigar.blendName.title')}
            text={blendName}
            titleIconProps={{ icon: 'mdi:cigar' }}
          />
        )}
        {Boolean(publicName) && (
          <DataCardRow
            title={t('qrCodeInfoPage.cigar.publicName.title')}
            text={publicName}
            titleIconProps={{ icon: 'ion:text-outline' }}
          />
        )}
        {isString(inventedAt) && (
          <DataCardRow
            title={t('qrCodeInfoPage.cigar.inventedAt.title')}
            text={t('date.short', { date: parseISO(inventedAt) })}
            titleIconProps={{ icon: 'ion:calendar-outline' }}
          />
        )}
        {Boolean(description) && <Typography color={'text.secondary'}>{description}</Typography>}
      </DataCardSection>

      {Boolean(tier) && (
        <DataCardSection>
          <DataCardRow
            title={t('qrCodeInfoPage.cigar.tier.title')}
            text={tier}
            titleIconProps={{ icon: 'ion:layers-outline' }}
          />
        </DataCardSection>
      )}

      {shape && (
        <DataCardSection title={t('qrCodeInfoPage.cigar.sectionTitle.shape')}>
          {Boolean(shapeName) && (
            <DataCardRow
              title={t('qrCodeInfoPage.cigar.shape.title')}
              text={shapeName}
              titleIconProps={{ icon: 'ion:document-text-outline' }}
            />
          )}
          {isNumber(length) && (
            <DataCardRow
              title={t('qrCodeInfoPage.cigar.length.title')}
              text={t('qrCodeInfoPage.cigar.length.text', { value: length })}
              titleIconProps={{ icon: 'mdi:ruler' }}
            />
          )}
          {isNumber(ringGauge) && (
            <DataCardRow
              title={t('qrCodeInfoPage.cigar.ringGauge.title')}
              text={t('qrCodeInfoPage.cigar.ringGauge.text', { value: ringGauge })}
              titleIconProps={{ icon: 'mdi:diameter-outline' }}
            />
          )}
        </DataCardSection>
      )}

      {some(tobaccoLeaves, (value) => isArray(value) && value?.length > 0) && (
        <DataCardSection title={t('qrCodeInfoPage.cigar.sectionTitle.leaves')}>
          {filler && filler.length > 0 && (
            <DataCardRow
              title={t('qrCodeInfoPage.cigar.filler.title')}
              text={filler}
              titleIconProps={{ icon: 'ion:leaf' }}
            />
          )}
          {wrapper && wrapper.length > 0 && (
            <DataCardRow
              title={t('qrCodeInfoPage.cigar.wrapper.title')}
              text={wrapper}
              titleIconProps={{ icon: 'ion:leaf-outline' }}
            />
          )}
          {binder && binder.length > 0 && (
            <DataCardRow
              title={t('qrCodeInfoPage.cigar.binder.title')}
              text={binder}
              titleIconProps={{ icon: 'ion:leaf-outline' }}
            />
          )}
        </DataCardSection>
      )}
    </>
  );
};

CigarData.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CigarData;
