import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// dayjs
import dayjs from 'dayjs';
// api
import attendanceApi from '../../api/attendanceApi';
// import payrollApi from '../../api/payrollApi';
// redux
import { SetAlertAndClose } from './AlertReducer';
// enums
import { AttendanceStatusEnum, LoadingStatusEnum } from '../../constants/enums';

// ----------------------------------------------------------------------------

// THUNKS
export const CreateAttendance = createAsyncThunk('attendance/createAttendance', async (data, thunkAPI) => {
  const chosenDay = dayjs(data.currentDay).format('YYYY-MM-DD');
  data.currentDay = chosenDay;
  const res = await attendanceApi.create(data);

  if (res.status === 200) {
    thunkAPI.dispatch(SetAlertAndClose({ data: res.data }));
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const EditAttendance = createAsyncThunk('attendance/editAttendance', async ({ data, id }, thunkAPI) => {
  const res = await attendanceApi.update(data, id);

  if (res.status === 200) {
    thunkAPI.dispatch(SetAlertAndClose({ data: res.data }));
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const SetPendingStatus = createAsyncThunk('attendance/setPendingStatus', async (id, thunkAPI) => {
  const res = await attendanceApi.setPendingStatus(id);

  if (res.status === 200) return id;

  return thunkAPI.rejectWithValue();
});

export const GetAttendancesByWeeks = createAsyncThunk('attendance/getAttendancesByWeeks', async (__, thunkAPI) => {
  const res = await attendanceApi.getByWeeks();

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const GetAttendancesByMonth = createAsyncThunk('attendance/getAttendancesByMonth', async (date, thunkAPI) => {
  const dayjsDate = dayjs(date);

  const year = dayjsDate.year();
  const month = dayjsDate.month();

  const res = await attendanceApi.getByMonth({
    year,
    month,
  });

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const GetAttendanceFormData = createAsyncThunk('attendance/getAttendanceFormData', async (params, thunkAPI) => {
  const chosenDay = dayjs(params.date).format('YYYY-MM-DD');
  params.date = chosenDay;

  const res = await attendanceApi.getForm(params);

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

// export const GetPayrolls = createAsyncThunk('attendance/getPayrolls', async (params, thunkAPI) => {
//   params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
//   params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

//   const res = await payrollApi.get(params);

//   if (res.status === 200) return res.data;

//   return thunkAPI.rejectWithValue();
// });

// state
const initialState = {
  payrolls: [],
  attendances: [],
  formData: {
    employeeAttendances: [],
    currentDay: null,
    status: null,
    _id: null,
  },
  loading: 'idle',
};

// slice
export const attendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    ClearAttendance: () => initialState,
  },
  extraReducers: (builder) => {
    // ------------------CreateAttendance-------------------------
    builder.addCase(CreateAttendance.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(CreateAttendance.fulfilled, (state) => ({
      ...state,
      // formData: {
      // 	...state.formData,
      // 	...action.payload.data,
      // },
      // loading: 'succeeded',
      loading: 'pending',
    }));

    builder.addCase(CreateAttendance.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------EditAttendance-------------------------
    builder.addCase(EditAttendance.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(EditAttendance.fulfilled, (state, action) => ({
      ...state,
      formData: action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(EditAttendance.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------SetPendingStatus-------------------------
    builder.addCase(SetPendingStatus.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(SetPendingStatus.fulfilled, (state, action) => ({
      ...state,
      formData: {
        ...state.formData,
        status: AttendanceStatusEnum.Pending,
      },
      payrolls: state.payrolls.map((el) => {
        if (el._id === action.payload) {
          return {
            ...el,
            status: AttendanceStatusEnum.Pending,
          };
        }

        return el;
      }),
      loading: 'succeeded',
    }));

    builder.addCase(SetPendingStatus.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetAttendanceFormData-------------------------
    builder.addCase(GetAttendanceFormData.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetAttendanceFormData.fulfilled, (state, action) => ({
      ...state,
      formData: action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetAttendanceFormData.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetAttendancesByWeeks-------------------------
    builder.addCase(GetAttendancesByWeeks.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetAttendancesByWeeks.fulfilled, (state, action) => ({
      ...state,
      attendances: action.payload.data.list,
      loading: 'succeeded',
    }));

    builder.addCase(GetAttendancesByWeeks.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetAttendancesByMonth-------------------------
    builder.addCase(GetAttendancesByMonth.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetAttendancesByMonth.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetAttendancesByMonth.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetPayrolls-------------------------
    // builder.addCase(GetPayrolls.pending, (state) => ({
    //   ...state,
    //   payrollsLoading: 'pending',
    // }));

    // builder.addCase(GetPayrolls.fulfilled, (state, action) => ({
    //   ...state,
    //   payrolls: action.payload.data.list,
    //   payrollsLoading: 'succeeded',
    // }));

    // builder.addCase(GetPayrolls.rejected, (state) => ({
    //   ...state,
    //   payrollsLoading: 'failed',
    // }));
  },
});

// Export Actions
export const { ClearAttendance } = attendanceSlice.actions;

// Export Selectors
export const selectAttendanceFormData = (state) => state.attendance.formData;
export const selectAttendanceList = (state) => state.attendance.attendances;
export const selectAttendancePayrolls = (state) => state.attendance.payrolls;
export const selectAttendanceLoading = (state) => state.attendance.loading;
export const selectAttendanceIsLoading = (state) => state.attendance.loading === LoadingStatusEnum.Pending;

// export const selectAttendancePayrolls = (state) => state.attendance.payrolls;
// export const selectAttendancePayrollsLoading = (state) => state.attendance.payrollsLoading;

export default attendanceSlice.reducer;
