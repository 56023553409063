import PropTypes from 'prop-types';
// @mui
import { Typography } from '@mui/material';

// -------------------------------------------------------------------------

const DataCardTitle = ({ children, props }) => (
  <Typography textTransform={'uppercase'} variant="body1" color={'text.secondary'} noWrap {...props}>
    {children}
  </Typography>
);

DataCardTitle.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};

export default DataCardTitle;
