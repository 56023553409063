import { createSlice } from '@reduxjs/toolkit';
// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetUsers, ImportUsers } from './thunks';
// reducers
import {
  ClearSelectedDistributorUserReducer,
  GetUsersReducer,
  ImportUsersReducer,
  ResetDistributorsStateReducers,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'distributors',
  initialState,
  reducers: {
    ClearSelectedDistributorUser: ClearSelectedDistributorUserReducer,
    ResetDistributorsState: ResetDistributorsStateReducers,
  },
  extraReducers: (builder) => {
    // GetUsers
    addExtraReducer(builder, GetUsers, GetUsersReducer);
    // // GetById
    // addExtraReducer(builder, GetTobaccoById, GetTobaccoByIdReducer);
    // // Create
    // addExtraReducer(builder, CreateTobacco, CreateTobaccoReducer);
    // // Update
    // addExtraReducer(builder, UpdateTobacco, UpdateTobaccoReducer);
    // Import
    addExtraReducer(builder, ImportUsers, ImportUsersReducer);
  },
});

export default slice.reducer;
