import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { PropTypes } from 'prop-types';
// ws
import { io } from 'socket.io-client';
import { useAuth } from './AuthProvider';
// hooks
import { usePrevious } from '../hooks/useHasChanged';
// config
import config from '../config';

const wsURL = config.wsAdress;

//---------------------------------------------------

const SocketContext = createContext(null);

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  const { account, isAuthentificated } = useAuth();
  const { _id } = account || {};

  const prevIsAuthentificated = usePrevious(isAuthentificated);

  useEffect(() => {
    const socket = io(wsURL);

    setSocket(socket);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socket && _id && isAuthentificated) {
      socket.emit('userConnected', _id);
    }
  }, [socket, _id, isAuthentificated]);

  useEffect(() => {
    if (socket && !isAuthentificated && prevIsAuthentificated) {
      socket.emit('userDisconnected');
    }
  }, [socket, isAuthentificated, prevIsAuthentificated]);

  const value = useMemo(
    () => ({
      socket,
    }),
    [socket]
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const useSocket = () => useContext(SocketContext);

export default SocketProvider;
